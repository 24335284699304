import MainHeader from '../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from "./ManageUsers.module.css";
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/ui-common/CustomLoader';
import DeleteUserModal from '../../components/admin/DeleteUserModal';
import EditUserModal from '../../components/admin/EditUserModal';
import Button from 'react-bootstrap/Button';
import wcmatch from 'wildcard-match';
import firebase from 'firebase/compat/app';
import Footer from '../../components/navigation/Footer';
import RolesChecker from '../../utilities/RolesChecker';
import { useAuthContext } from '../../components/context/useAuthContext';
import Alert from 'react-bootstrap/Alert';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserRolesList } from '../../models/user-roles';
import { searchDocuments } from '../../components/database/search';


const ManageUsers = (props) => {

  // User list state
  const { user } = useAuthContext();
  const [displayNameContext, setDisplayNameContext] = useState(user?.displayName);
  const [rolesContext, setRolesContext] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);
  const [userRolesDropdownList, setUserRolesDropdownList] = useState([]);
  const [selectedRoleDropDown, setSelectedRoleDropDown] = useState("");

  const [loaderState, setLoaderState] = useState(false);
  const [origUserList, setOrigUserList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [displayName, setDisplayName] = useState('');

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
            alert('Unauthorized access.');
            navigate('/');
          }
          setDisplayNameContext(tokenResult.claims?.name);
          setRolesContext(parsedUserRoles);
          if (parsedUserRoles.find(role => role === "SUPERADMIN")) {
            setUserRolesList(UserRolesList);
            const rolesDropDown = [].concat(UserRolesList);
            rolesDropDown.push("ALL")
            setUserRolesDropdownList(rolesDropDown);
            setSelectedRoleDropDown("ALL");
            listAllUsers("ALL");
          } else {
            const filteredRoles = UserRolesList.filter(role => role !== "ADMIN" && role !== "SUPERADMIN");
            setUserRolesList(filteredRoles);
            setUserRolesDropdownList(filteredRoles);
            setSelectedRoleDropDown("USER");
            listAllUsers("USER");
          }
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(tokenResult) {
              const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setDisplayNameContext(tokenResult.claims?.name);
              setRolesContext(parsedUserRoles);
              if (parsedUserRoles.find(role => role === "SUPERADMIN")) {
                setUserRolesList(UserRolesList);
                const rolesDropDown = [].concat(UserRolesList);
                rolesDropDown.push("ALL")
                setUserRolesDropdownList(rolesDropDown);
                setSelectedRoleDropDown("ALL");
                listAllUsers("ALL");
              } else {
                const filteredRoles = UserRolesList.filter(role => role !== "ADMIN" && role !== "SUPERADMIN");
                setUserRolesList(filteredRoles);
                setUserRolesDropdownList(filteredRoles);
                setSelectedRoleDropDown("USER");
                listAllUsers("USER");
              }
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function displayNameListener(event) {
    setDisplayName(event.target.value);
    searchDisplayName(event.target.value);
  }
  async function selectRoleListener(role) {
    setSelectedRoleDropDown(role);
    await listAllUsers(role);
  }

  async function listAllUsers(selectedRole) {
    // Enable loader when request is being sent.
    setLoaderState(true);
    let searchFilters = [];
    if (selectedRole && selectedRole !== "ALL") {
      searchFilters = [
        {key: "roles", type: "array-contains", inputValue: selectedRole},
      ]
    } else if (!selectedRole && selectedRoleDropDown !== "ALL") {
      searchFilters = [
        {key: "roles", type: "array-contains", inputValue: selectedRoleDropDown},
      ]
    }
    try {
      const users = await searchDocuments("users", searchFilters, 1000, "displayName", "asc");
      setUserList(users.docs);
      setOrigUserList(users.docs);
      setLoaderState(false);
    } catch (error) {
      console.log(`${error}`);
      setUserList([]);
      setOrigUserList([]);
      setLoaderState(false);
    }
  }

  // Sort files Ascending order
  function sortItemsAsc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Sort files Descending order
  function sortItemsDesc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => b.displayName > a.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Search DisplayName Dynamically
  function searchDisplayName(displayName) {
    const isMatch = wcmatch(`*${displayName}*`);
    const myData = [];
    origUserList.forEach((user) => {
      if (isMatch(user.displayName)) {
        myData.push(user);
      }
    });
    setUserList(myData);
  }

  function getDeleteConfirmation(isSuccess, displayName) {
    if (isSuccess){
      const arr = userList.filter(obj => obj.displayName !== displayName);
      setUserList(arr);
      alert(`Successfully deleted ${displayName}`);
    }
  }

  async function getEditConfirmation(isSuccess, displayName) {
    if (isSuccess){
      await listAllUsers();
      alert(`Successfully edited ${displayName}`);
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayNameContext}
        roles={rolesContext}
      />
      <center>
        <h4 style={{marginTop:20}}>Manage Users</h4>
        <div style={{display:'inline-block'}}>
          <label
            htmlFor='displayName'
          >
            Display Username:
          </label> &nbsp;
          <input
            type='displayName'
            value={displayName}
            id='displayName'
            onChange={displayNameListener}
          />
          <br></br>
          <Button
            variant="warning"
            onClick={sortItemsAsc}
            style={{marginTop:10}}
            className={classes.buttonStyle}
          >
            Sort Up
          </Button> &nbsp;
          <Button
            variant="warning"
            onClick={sortItemsDesc}
            style={{marginTop:10}}
            className={classes.buttonStyle}
          >
            Sort Down
          </Button>

          <DropdownButton
            variant="warning"
            key="role"
            title={selectedRoleDropDown}
            style={{marginTop:10}}
          >
            {
              userRolesDropdownList.map((role) => (
                <Dropdown.Item key={role} onClick={() => selectRoleListener(role)}>
                  {role}
                </Dropdown.Item>
              ))
            }
          </DropdownButton>
        </div>
        <br></br>
        <div className={classes.viewBox}>
          <ul id="screenshots" className={classes.tablestyle}>
          { userList.length > 0
            ? userList.map((val, index) => (
                <div key={val.displayName}>
                  <label>
                  <li>
                    <b>{val.displayName}</b>
                  </li>
                  &nbsp;&nbsp;
                  <EditUserModal
                    details={val}
                    index={index}
                    roles={val.roles}
                    userRolesList={userRolesList}
                    onEditConfirmation={getEditConfirmation}
                  />
                  &nbsp;&nbsp;
                  </label>
                  <DeleteUserModal
                    uid={val.uid}
                    displayName={val.displayName}
                    email={val.email}
                    onDeleteConfirmation={getDeleteConfirmation}
                  />
                </div>
              ))
            : <Alert
                key="secondary"
                variant="secondary"
              >
                No users match the search text.
              </Alert>
          }
          </ul>
        </div>
      </center>
      <Footer />
    </div>
    </CustomLoader>
  );
};

export default ManageUsers;
