import { round } from 'mathjs';
import { updateDocument } from "../components/database/update";

async function CalculateProjectTotals(allocGroups, projectDocument) {
    let newTotalAllotment = 0;
    let newTotalDisbursements = 0;
    let updateUser = "SYSTEM";

    allocGroups.forEach(function(allocGroup) {
      if (allocGroup.totalAllotment) {
        newTotalAllotment += parseFloat(allocGroup.totalAllotment);
      }
      if (allocGroup.totalDisbursements) {
        newTotalDisbursements += parseFloat(allocGroup.totalDisbursements);
      }
      updateUser = allocGroup.modifiedBy ?? allocGroup.createdBy;
    });
    const newUnspentAllotment = newTotalAllotment - newTotalDisbursements;
    // console.log(newTotalAllotment);
    // console.log(newTotalDisbursements);
    // console.log(newUnspentAllotment);

    // check if there are changes.
    // If no changes, do not update form or update db project document
    // console.log("CALCULATED-4");
    if ( projectDocument.totalAllotment !== newTotalAllotment ||
        projectDocument.totalDisbursements !== newTotalDisbursements ||
        projectDocument.unspentAllotment !== newUnspentAllotment
      ) {
        const totals = {
          totalAllotment: round(newTotalAllotment, 2),
          totalDisbursements: round(newTotalDisbursements, 2),
          unspentAllotment: round(newUnspentAllotment, 2)
        }
        await updateDocument("projects", projectDocument.docId, totals, updateUser);
        console.log("RECALCULATED");
        return totals;
    } else {
      return {};
    }
  }

export default CalculateProjectTotals

