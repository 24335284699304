import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../../components/database/insert";
import short from "short-uuid";
import Spinner from "react-bootstrap/Spinner";
import DropDownSelection from "../../components/ui-common/DropDownSelection";
import { BudgetItemTypeEnum, BudgetItemTypeList } from "../../models/budget-item-type";
import CalculateAllotment from "../../utilities/CalculateAllotment";


const InsertBudgetItemButtonModal = (props) => {

  const fundCode = props.fundCode;
  const displayName = props.displayName;
  const groupName = props.groupName;
  const allotmentPercentage = props.allotmentPercentage;
  const [date, setDate] = useState("");
  const [docType, setDocType] = useState("");
  const [particulars, setParticulars] = useState("");
  const [prNumber , setPrNumber] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [dvNumber, setDvNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [supplier, setSupplier] = useState("");
  const [claimant, setClaimant] = useState("");
  const [obligations, setObligations] = useState("");
  const [disbursements, setDisbursements] = useState("");

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  function handleClose(status) {
    clearInputs(status);
    setShow(false);
  };
  function handleShow() {
    setShow(true);
  };

  function dateListener(event) {
    setDate(event.target.value);
  }
  function particularsListener(event) {
    setParticulars(event.target.value);
  }
  function docTypeListener(value) {
    setDocType(value);
  }
  function prNumberListener(event) {
    setPrNumber(event.target.value);
  }
  function poNumberListener(event) {
    setPoNumber(event.target.value);
  }
  function dvNumberListener(event) {
    setDvNumber(event.target.value);
  }
  function amountListener(event) {
    setAmount(event.target.value);
  }
  function supplierListener(event) {
    setSupplier(event.target.value);
  }
  function claimantListener(event) {
    setClaimant(event.target.value);
  }
  function obligationsListener(event) {
    setObligations(event.target.value);
  }
  function disbursementsListener(event) {
    setDisbursements(event.target.value);
  }

  async function createNewBudgetItem(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (!validateDropDowns()) {
      return;
    }
    const budgetData = {
      fundCode: fundCode,
      groupName: groupName,
      budgetId: short.generate(),
      date: date,
      docType: docType,
      particulars: particulars,
      amount: amount
    };
    // add allotment calclation if docType = release
    if (docType === "RELEASE") {
      const allotment = CalculateAllotment(allotmentPercentage, amount);
      budgetData.allotment = allotment;
    } else {
      budgetData.prNumber = prNumber;
      budgetData.poNumber = poNumber;
      budgetData.dvNumber = dvNumber;
      budgetData.supplier = supplier;
      budgetData.claimant = claimant;
      budgetData.obligations = obligations;
      budgetData.disbursements = disbursements;
    }
    // console.log(budgetData);
    try {
      setLoaderState(true);
      await insertDocument(
        "budget_items",
        budgetData,
        "",
        false,
        "",
        false,
        displayName
      );
      setLoaderState(false);
      alert ("New budget item created successfully.");
      handleClose(true);
    } catch (error) {
      console.log(error);
      alert ("Error during budget item creation.");
      setLoaderState(false);
    };
  }

  function validateDropDowns() {
    if (!docType) {
      return false;
    }
    return true;
  }

  function clearInputs(status) {
    setDate("");
    setDocType("");
    setParticulars("");
    setPrNumber("");
    setPoNumber("");
    setDvNumber("");
    setAmount("");
    setSupplier("");
    setClaimant("");
    setObligations("");
    setDisbursements("");
    setValidated(false);
    props.onInsertConfirmation(status);
  }

  return (
    <React.Fragment>
      <Button
        variant="warning"
        onClick={handleShow}
      >
          Create Budget Item
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={() => (handleClose(false))}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Create New Budget Item</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
          <Form noValidate validated={validated} onSubmit={createNewBudgetItem}>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="date">Date</InputGroup.Text>
            <Form.Control
              required
              type="date"
              value={date}
              onChange={dateListener}
            />
            <InputGroup.Text id="docType">Type</InputGroup.Text>
            <DropDownSelection
              label="type"
              validated={validated}
              required={true}
              dropDownList={BudgetItemTypeList}
              dropDownListener={docTypeListener}
            />
            <Form.Control.Feedback type="invalid">
              Date and Type should not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="particulars">Particulars:</InputGroup.Text>
            <Form.Control
              required
              type="text"
              placeholder="Particulars"
              value={particulars}
              onChange={particularsListener}
            />
            <Form.Control.Feedback type="invalid">
              Particulars should not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="amount">Amount:</InputGroup.Text>
            <Form.Control
              type="text"
              required
              placeholder="Amount"
              pattern="^[0-9]+(\.[0-9]+)?$"
              value={amount}
              onChange={amountListener}
            />
            <Form.Control.Feedback type="invalid">
              Amount should be a number and not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            { docType !== BudgetItemTypeEnum.RELEASE
              ? <Fragment>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="obligations">Obligations:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Obligations"
                    pattern="^[0-9]+(\.[0-9]+)?$"
                    value={obligations}
                    onChange={obligationsListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Obligations should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>

                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="disbursements">Disbursements:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Disbursements"
                    pattern="^[0-9]+(\.[0-9]+)?$"
                    value={disbursements}
                    onChange={disbursementsListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Disbursements should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="prNumber">PR Number:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="PR Number (optional)"
                    value={prNumber}
                    onChange={prNumberListener}
                  />
                  <InputGroup.Text id="poNumber">PO Number:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="PO Number (optional)"
                    value={poNumber}
                    onChange={poNumberListener}
                  />
                  </InputGroup>

                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="dvNumber">DV Number:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="DV Number (optional)"
                    value={dvNumber}
                    onChange={dvNumberListener}
                  />
                  </InputGroup>

                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="supplier">Supplier:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Supplier (optional)"
                    value={supplier}
                    onChange={supplierListener}
                  />
                  <InputGroup.Text id="claimant">Claimant:</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Claimant (optional)"
                    value={claimant}
                    onChange={claimantListener}
                  />
                  </InputGroup>
                </Fragment>
                : null
            }

          </Form.Group>

          { loaderState
            ? <center>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </center>
            : <center>
                <Button
                  variant="secondary"
                  onClick={() => (handleClose(false))}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button
                  variant="warning"
                  type="submit"
                >
                  Create
                </Button>
              </center>
          }
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertBudgetItemButtonModal

