import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


const MenuLinks = (props) => {

  const linkObjectsList = props.objectLinksList;
  const show = props.collapse;

  return (
    <Collapse in={show}>
      <ul>
        {
          linkObjectsList.map((val) => (
            <li key={val.link}>
              <Link to={val.link}>
              <Button variant="warning">
                {val.label}
              </Button>
              </Link>
            </li>
          ))
        }
      </ul>
    </Collapse>
  )
}

export default MenuLinks;
