import wcmatch from 'wildcard-match';


// Search DisplayName Dynamically
function FullTextSearch(inputData, searchKey, elementObjectKey, convertToUpperCase) {

  let isMatch = wcmatch(`*${searchKey}*`);
  if (convertToUpperCase) {
    isMatch = wcmatch(`*${searchKey.toUpperCase()}*`);
  }
  const myData = [];
  inputData.forEach((item) => {
    if (isMatch(item[elementObjectKey].toUpperCase())) {
      myData.push(item);
    }
  });
  return myData;
}


export default FullTextSearch

