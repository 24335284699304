import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import InputSearchPage from "../../components/ui-common/InputSearchPage";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";


const ExpenseTypeInputSearch = (props) => {

  // Dynamic Parameters
  const dbName = "allocation_expense_type";
  const dbResultLimit = 100;
  const label = "Allocation Expense Type";
  const elementObjectKey = "allocationExpenseType";
  const elementObjectDescKey = `${elementObjectKey}Description`;

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <InputSearchPage
      dbName={dbName}
      dbResultLimit={dbResultLimit}
      label={label}
      elementObjectKey={elementObjectKey}
      elementObjectDescKey={elementObjectDescKey}
      displayName={displayName}
      roles={roles}
    />
  );
}

export default ExpenseTypeInputSearch;
