import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./components/context/AuthProvider.js";
import App from "./App.js";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ManageUsers from "./pages/Admin/ManageUsers";
import CreateUser from "./pages/Admin/CreateUser.js";
import SignOut from "./pages/SignOut.js";
import UserProfile from "./pages/User/UserProfile.js";
import ManageAdmins from "./pages/SuperAdmin/ManageAdmins.js";
import ProjectCreate from "./pages/Budget/ProjectCreate.js";
import ProjectEdit from "./pages/Budget/ProjectEdit.js";
import ProjectSearch from "./pages/Budget/ProjectSearch.js";
import AllocGroupEdit from "./pages/Budget/AllocGroupEdit.js";
import InventoryEdit from './pages/Inventory/InventoryEdit.js';
import GroupInputSearch from './pages/Group/GroupInputSearch.js';
import PersonInputSearch from './pages/Person/PersonInputSearch.js';
import InventorySearch from './pages/Inventory/InventorySearch.js';
import ProjectSearchInventory from './pages/Inventory/ProjectSearchInventory.js';
import ProjectEditInventory from './pages/Inventory/ProjectEditInventory.js';
import AllocGroupEditInventory from './pages/Inventory/AllocGroupEditInventory.js';
import ExpenseTypeInputSearch from './pages/ExpenseType/ExpenseTypeInputSearch.js';
// import FirstAdmin from "./pages/SuperAdmin/FirstSetupAdmin.js";

const systemAdminGroup = ["ADMIN", "SUPERADMIN"];
const budgetGroup = ["BUDGETADMIN", "BUDGETVIEWER"];


ReactDOM.render(
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/manageadmins" element={<ManageAdmins
            allowedRolesList={["SUPERADMIN"]}
          />}
        />
        <Route path="/manageusers" element={<ManageUsers
            allowedRolesList={systemAdminGroup}
          />}
        />
        <Route path="/createuser" element={<CreateUser
            allowedRolesList={systemAdminGroup}
          />}
        />

        <Route path="/projectcreate" element={<ProjectCreate
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/projectdetails/:fundCode" element={<ProjectEdit
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/projectsearch" element={<ProjectSearch
          allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/allocgroupdetails/:fundCode/:groupName/:allocationExpenseType" element={<AllocGroupEdit
          allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/group" element={<GroupInputSearch
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/person" element={<PersonInputSearch
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />
        <Route path="/allocationexpensetype" element={<ExpenseTypeInputSearch
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
          />}
        />

        <Route path="/projectinventorydetails/:fundCode/:groupName/:budgetId" element={<InventoryEdit
            allowedRolesList={[...systemAdminGroup, ...budgetGroup]}
            mode="inventory"
          />}
        />

        <Route path="/inventorysearch" element={<InventorySearch
            allowedRolesList={[...systemAdminGroup]}
            mode="inventory"
          />}
        />
        <Route path="/projectsearchinventory" element={<ProjectSearchInventory
            allowedRolesList={[...systemAdminGroup]}
            mode="inventory"
          />}
        />
        <Route path="/projectdetailsinventory/:fundCode" element={<ProjectEditInventory
            allowedRolesList={[...systemAdminGroup]}
            mode="inventory"
          />}
        />
        <Route path="/allocgroupdetailsinventory/:fundCode/:groupName" element={<AllocGroupEditInventory
            allowedRolesList={[...systemAdminGroup]}
            mode="inventory"
          />}
        />


        <Route path="/signout" element={<SignOut />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
    </BrowserRouter>,
  document.getElementById("root")
);

// Only use once during initial deployment for first admin user.
// <Route path="/backdoor" element={<FirstAdmin />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
