export const UserRolesEnum = {
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
  BUDGETADMIN: "BUDGETADMIN",
  BUDGETVIEWER: "BUDGETVIEWER",
  INVENTORYADMIN: "INVENTORYADMIN",
  INVENTORYVIEWER: "INVENTORYVIEWER",
}

export const UserRolesList = [
  "ADMIN",
  "SUPERADMIN",
  "BUDGETADMIN",
  "BUDGETVIEWER",
  "INVENTORYADMIN",
  "INVENTORYVIEWER",
]
