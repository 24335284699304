import * as React from "react";
import { Fragment } from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectSearchInventory.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Footer from "../../components/navigation/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { searchDocuments } from "../../components/database/search";
import { searchPrevDocuments } from "../../components/database/searchPrev";
import { searchNextDocuments } from "../../components/database/searchNext";
import { listDocuments } from "../../components/database/get";
import RolesChecker from "../../utilities/RolesChecker";


const ProjectSearchInventory = (props) => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  const [personsList, setPersonsList] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState("");

  // Search related
  const paginationResultCount = 20;
  const [searchInput, setSearchInput] = useState("");
  const [beginningDateRangeInput, setBeginningDateRangeInput] = useState("");
  const [endDateRangeInput, setEndDateRangeInput] = useState("");
  const [searchOption, setSearchOption] = useState({label: 'Fund Code', value: "fundCode"});
  const [searchFilter, setSearchFilter] = useState([]);

  // Results
  const [searchResults, setSearchResults] = useState([]);
  const [firstVisibleSearchResults, setFirstVisibleSearchResults] = useState();
  const [lastVisibleSearchResults, setLastVisibleSearchResults] = useState();
  const [isNextAvailable, setIsNextAvailable] = useState(false);
  const [isPrevAvailable, setIsPrevAvailable] = useState(false);

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    getPersonsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function getPersonsList() {
    const queryResult = await listDocuments("persons", 'person', 'asc');
    setPersonsList([...[{person: "ALL"}],...queryResult]);
  }

  function searchInputListener(event) {
    setSearchInput(event.target.value);
  }
  function selectedPersonListener(person) {
    setSelectedPerson(person);
  }
  function beginningDateRangeInputListener(event) {
    setBeginningDateRangeInput(event.target.value);
  }
  function endDateRangeInputListener(event) {
    setEndDateRangeInput(event.target.value);
  }
  function searchOptionListener(value) {
    setSearchOption(mapSearchOption(value));
  }

  async function searchProject() {
    let searchFilter = [];
    if (searchOption.value === "endDate" ||
      searchOption.value === "startDate") {
        if (beginningDateRangeInput && endDateRangeInput) {
          searchFilter.push({key: searchOption.value, type: ">=", inputValue: beginningDateRangeInput});
          searchFilter.push({key: searchOption.value, type: "<", inputValue: endDateRangeInput});
        } else {
          alert ("Beginning Date and End Date input should not be blank.");
          return;
        }
    } else {
      if (searchInput) {
        const searchInputEnd = searchInput.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        searchFilter.push({key: searchOption.value, type: ">=", inputValue: searchInput});
        searchFilter.push({key: searchOption.value, type: "<", inputValue: searchInputEnd});
      }
    }
    console.log(searchFilter);

    if (selectedPerson !== "ALL" && selectedPerson) {
      searchFilter.push({key: "leader", type: "==", inputValue: selectedPerson});
    }

    setSearchFilter(searchFilter);
    try {
      setLoaderState(true);
      const searchResults = await searchDocuments(
        "projects",
        searchFilter,
        paginationResultCount,
        searchOption.value,
        "asc"
      );
      setSearchResults(searchResults.docs);
      setLastVisibleSearchResults(searchResults.lastVisibleDocument);
      setFirstVisibleSearchResults(searchResults.firstVisibleDocument);
      setIsNextAvailable(searchResults.isNextAvailable);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
    }
  }

  async function searchPrev() {
    try {
      const searchResults = await searchPrevDocuments(
        "projects",
        searchFilter,
        paginationResultCount,
        firstVisibleSearchResults,
        searchOption.value,
        "asc"
      );
      // console.log(searchResults.docs);
      setSearchResults(searchResults.docs);
      setLastVisibleSearchResults(searchResults.lastVisibleDocument);
      setFirstVisibleSearchResults(searchResults.firstVisibleDocument);
      setIsPrevAvailable(searchResults.isPrevAvailable);
      setIsNextAvailable(searchResults.isNextAvailable);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
    }
  }

  async function searchNext() {
    try {
      const searchResults = await searchNextDocuments("projects", searchFilter, paginationResultCount, lastVisibleSearchResults, searchOption.value, "asc");
      // console.log(searchResults.docs);
      setSearchResults(searchResults.docs);
      setLastVisibleSearchResults(searchResults.lastVisibleDocument);
      setFirstVisibleSearchResults(searchResults.firstVisibleDocument);
      setIsPrevAvailable(searchResults.isPrevAvailable);
      setIsNextAvailable(searchResults.isNextAvailable);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
    }
  }

  function mapSearchOption(searchOption) {
    let mappedSearchOption;
    switch(searchOption) {
      case 'Fund Code':
        mappedSearchOption = {label: 'Fund Code', value: 'fundCode'};
        break;
      case 'Description':
        mappedSearchOption = {label: 'Description', value: 'description'};
        break;
      case 'Start Date':
        mappedSearchOption = {label: 'Start Date', value: 'startDate'};
        break;
      case 'End Date':
        mappedSearchOption = {label: 'End Date', value: 'endDate'};
        break;
      default:
        mappedSearchOption = null;
    }
    return mappedSearchOption;
  }

  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Search Project for Inventory Creation
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate>
        <Form.Group className="mb-3" controlId="searchProject">

        { searchOption.value === "endDate" || searchOption.value === "startDate"
          ? <Fragment>
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="beginning-date-range-input">
                Beginning Date Input:
              </InputGroup.Text>
              <Form.Control
                required
                type="date"
                value={beginningDateRangeInput}
                onChange={beginningDateRangeInputListener}
              />
              <InputGroup.Text id="end-date-range-input">
                End Date Input:
              </InputGroup.Text>
              <Form.Control
                required
                type="date"
                value={endDateRangeInput}
                onChange={endDateRangeInputListener}
              />
              </InputGroup>

              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="element">Project Leader: </InputGroup.Text>
              <DropdownButton
                id="search-options-person-button"
                title={selectedPerson}
                variant="warning"
              >
                {
                  personsList.map((val) => (
                    <Dropdown.Item
                      key={val.person}
                      onClick={() => {selectedPersonListener(val.person)}}
                    >
                      {val.person}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
              </InputGroup>
            </Fragment>
          : <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="search-input">
              Search Input:
            </InputGroup.Text>
            <Form.Control
              required
              type="text"
              placeholder="Search Input for Fund Code, Description or Leader."
              value={searchInput}
              onChange={searchInputListener}
            />
            &nbsp;
            <InputGroup.Text id="element">Project Leader: </InputGroup.Text>
            <DropdownButton
              id="search-options-person-button"
              title={selectedPerson}
              variant="warning"
            >
              {
                personsList.map((val) => (
                  <Dropdown.Item
                    key={val.person}
                    onClick={() => {selectedPersonListener(val.person)}}
                  >
                    {val.person}
                  </Dropdown.Item>
                ))
              }
            </DropdownButton>
            </InputGroup>
        }
          <center>
          <InputGroup className="mb-3" style={{display:"inline-block", marginTop:5}}>
            <DropdownButton
              variant="warning"
              id="search-options-dropdown-button"
              title={searchOption.label}
            >
              <Dropdown.Item onClick={() => {searchOptionListener('Fund Code')}}>Fund Code</Dropdown.Item>
              <Dropdown.Item onClick={() => {searchOptionListener('Description')}}>Description</Dropdown.Item>
              <Dropdown.Item onClick={() => {searchOptionListener('Start Date')}}>Start Date</Dropdown.Item>
              <Dropdown.Item onClick={() => {searchOptionListener('End Date')}}>End Date</Dropdown.Item>
            </DropdownButton>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="warning"
              onClick={searchProject}
            >
              Search Project
            </Button>
            </InputGroup>
            </center>

        </Form.Group>
        </Form>
        <ListGroup style={{overflowY:"scroll", maxHeight:"230px"}}>
        { searchResults.length > 0
          ? searchResults.map((row) => (
              <ListGroup.Item
                key={row.fundCode}
                action
                onClick={() => navigate(`/projectdetailsinventory/${row.fundCode}`)}
              >
                <b>Fund Code:</b> {row.fundCode} &nbsp;
                { row.description
                  ? <Fragment><b>Description:</b> {row.description} &nbsp;</Fragment>
                  : null
                }
                <b>Leader:</b> {row.leader}
              </ListGroup.Item>
            ))
          : <ListGroup.Item key="noResults">
              <center><b>No Results</b></center>
            </ListGroup.Item>
        }
        </ListGroup>
        <br></br>
        <center>
        { isPrevAvailable
          ? <Button
              variant="warning"
              onClick={searchPrev}
            >
              Previous
            </Button>
          : null
        }
        &nbsp;&nbsp;
        { isNextAvailable
          ? <Button
              variant="warning"
              onClick={searchNext}
            >
              Next
            </Button>
          : null
        }
        </center>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectSearchInventory;
