import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectCreate.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import { insertDocument } from "../../components/database/insert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Footer from "../../components/navigation/Footer";
import InsertDataDropdownModal from "../../components/ui-common/InsertDataDropdownModal";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import ValidateKeyInputs from "../../utilities/ValidateKeyInputs";
import RolesChecker from "../../utilities/RolesChecker";
import ValidateDropDowns from "../../utilities/ValidateDropDowns";
import { ProjectTypeEnum, ProjectTypeList } from "../../models/project-type";


const ProjectCreate = (props) => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  // Form inputs
  const [validated, setValidated] = useState(false);
  const [fundCode, setFundCode] = useState("");
  const [description, setDescription] = useState("");
  const [projectType, setProjectType] = useState(ProjectTypeEnum.REVOLVING);
  const [leader, setLeader] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [comments, setComments] = useState("");

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              setRoles(parsedUserRoles);
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function fundCodeListener(event) {
    setFundCode(event.target.value);
  }
  function descriptionListener(event) {
    setDescription(event.target.value);
  }
  function projectTypeListener(value) {
    setProjectType(value);
  }
  function leaderListener(person) {
    setLeader(person);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
  }
  function endDateListener(event) {
    if (!startDate) {
      alert("Please set start date first.")
      return;
    }
    if (startDate > event.target.value) {
      alert("End date should be greater than start date.")
      return;
    }
    setEndDate(event.target.value);
  }
  function totalCostListener(event) {
    setTotalCost(event.target.value);
  }
  function commentsListener(event) {
    setComments(event.target.value);
  }

  async function createProject(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const projectData = {
      fundCode: fundCode,
      description: description ?? " ",
      projectType: projectType,
      leader: leader,
      startDate: startDate,
      endDate: endDate,
      originalEndDate: endDate,
      totalCost: totalCost ?? " ",
      totalAllotment: 0,
      totalDisbursements: 0,
      unspentAllotment: 0,
      comments: comments ?? " ",
    };
    console.log(projectData);
    if (!ValidateDropDowns(projectData, ["leader"])) {
      return;
    }
    if (!ValidateKeyInputs(projectData, "fundCode")) {
      setValidated(false);
      alert ("Invalid character detected. / is not allowed.");
      return;
    }
    setLoaderState(true);
    const uniqueKeyFilter = [
      { key: "fundCode", inputValue: fundCode}
    ];
    try {
      const success = await insertDocument(
        "projects",
        projectData,
        "",
        false,
        uniqueKeyFilter,
        true,
        displayName
      );
      setLoaderState(false);
      if (success) {
        alert(`Successfully created project ${fundCode}. Please start adding allocation groups.`);
        navigate(`/projectdetails/${fundCode}`);
      }
    } catch (error) {
      setLoaderState(false);
    }
    setValidated(false);
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Create New Project
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={createProject}>
        <Form.Group className="mb-3" controlId="createProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Fund Code"
            value={fundCode}
            pattern="[^/]*"
            onChange={fundCodeListener}
          />
          <Form.Control.Feedback type="invalid">
            Fund code should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="description">
            Desciption:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Description about the project. (optional)"
            value={description}
            onChange={descriptionListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="project-type">
            Project Type:
          </InputGroup.Text>
          <DropdownButton
            id="search-options-project-button"
            title={projectType}
            variant="warning"
          >
            {
              ProjectTypeList.map((projectType) => (
                <Dropdown.Item
                  key={projectType}
                  onClick={() => {projectTypeListener(projectType)}}
                >
                  {projectType}
                </Dropdown.Item>
              ))
            }
          </DropdownButton>
          &nbsp; &nbsp;
          <InputGroup.Text id="project-leader">
            Project Leader:
          </InputGroup.Text>
          <InsertDataDropdownModal
            validated={validated}
            selectedElement={leader}
            onSelectedElement={leaderListener}
            displayName={displayName}
            elementObjectKey="person"
            dbName="persons"
            label="Leader"
            required={true}
          />
          </InputGroup>
          <CustomFeedbackValidation
            validated={validated}
            evaluatedValue={leader}
            label="Leader"
          />

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="start-date">
            Start Date:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="Start Date"
            value={startDate}
            inputMode='none'
            onChange={startDateListener}
          />
          <InputGroup.Text id="end-date">
            End Date:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="End Date"
            value={endDate}
            inputMode='none'
            onChange={endDateListener}
          />
          <Form.Control.Feedback type="invalid">
            Start and End date should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="total-cost">
            Total Cost:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Project Total Cost. (optional)"
            pattern="^[0-9]+(\.[0-9]+)?$"
            value={totalCost}
            onChange={totalCostListener}
          />
          <Form.Control.Feedback type="invalid">
            Total Cost should be a number.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="comments">
            Comments:
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            placeholder="Comments about the project. (optional)"
            value={comments}
            onChange={commentsListener}
          />
          </InputGroup>
          <br></br>
          <center>
            <Button
              variant="warning"
              type="submit"
            >
              Create Project
            </Button>
          </center>
        </Form.Group>
        </Form>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectCreate;
