import { Fragment } from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectEdit.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { getDocument } from "../../components/database/get";
import { updateDocument } from "../../components/database/update";
import InsertAllocGroupButtonModal from "./InsertAllocGroupButtonModal";
import { deleteProject } from "../../components/database/delete";
import DeleteConfirmationModal from "../../components/ui-common/DeleteConfirmationModal";
import CalculateProjectTotals from "../../utilities/CalculateProjectTotals";
import InsertDataDropdownModal from "../../components/ui-common/InsertDataDropdownModal";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import RolesChecker from "../../utilities/RolesChecker";
import CheckIsAdmin from "../../utilities/CheckIsAdmin";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebaseConfig";


const ProjectEdit = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // Form inputs
  const fundCode = params.fundCode;
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [projectType, setProjectType] = useState("");
  const [leader, setLeader] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [comments, setComments] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createDate, setCreateDate] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [allocGroupList, setAllocGroupList] = useState([]);
  const [formHasNoChanges, setformHasNoChanges] = useState(true);
  const [pdfReport, setPdfReport] = useState("");

  const [totalAllotment, setTotalAllotment] = useState("");
  const [totalDisbursements, setTotalDisbursements] = useState("");
  const [unspentAllotment, setUnspentAllotment] = useState("");

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setIsAdmin(CheckIsAdmin(parsedUserRoles));
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              setRoles(parsedUserRoles);
              setIsAdmin(CheckIsAdmin(parsedUserRoles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchProject() {
    const projectResult = await getDocument("projects", "fundCode", fundCode);
    populateFields(projectResult[0]);
    setDocumentId(projectResult[0].docId);
    await searchAllocationGroups(projectResult[0]);
  }

  async function searchAllocationGroups(projectDocument) {
    const groupResult = await getDocument("allocation_groups", "fundCode", fundCode);
    setAllocGroupList(groupResult);
    const result = await CalculateProjectTotals(groupResult, projectDocument);
    if (result) {
      setTotalAllotment(result.totalAllotment);
      setTotalDisbursements(result.totalDisbursements);
      setUnspentAllotment(result.unspentAllotment);
    }
  }

  function populateFields(projectDocument) {
    setDescription(projectDocument.description);
    setProjectType(projectDocument.projectType);
    setLeader(projectDocument.leader);
    setStartDate(projectDocument.startDate);
    setEndDate(projectDocument.endDate);
    setOriginalEndDate(projectDocument.originalEndDate ?? " ");
    setComments(projectDocument.comments);
    setCreatedBy(projectDocument.createdBy);
    setCreateDate(projectDocument.createDate.toDate().toLocaleString());
    setTotalAllotment(projectDocument.totalAllotment);
    setTotalDisbursements(projectDocument.totalDisbursements);
    setUnspentAllotment(projectDocument.unspentAllotment);
  }

  function descriptionListener(event) {
    setDescription(event.target.value);
    setformHasNoChanges(false);
  }
  function leaderListener(person) {
    setLeader(person);
    setformHasNoChanges(false);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
    setformHasNoChanges(false);
  }
  function endDateListener(event) {
    if (!startDate) {
      alert("Please set start date first.")
      return;
    }
    if (startDate > event.target.value) {
      alert("End date should be greater than start date.")
      return;
    }
    setEndDate(event.target.value);
    setformHasNoChanges(false);
  }
  function totalCostListener(event) {
    setTotalCost(event.target.value);
    setformHasNoChanges(false);
  }
  function commentsListener(event) {
    setComments(event.target.value);
    setformHasNoChanges(false);
  }

  async function editProject(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const projectData = {
      fundCode: fundCode,
      description: description ?? " ",
      projectType: projectType,
      leader: leader,
      startDate: startDate,
      endDate: endDate,
      totalCost: totalCost ?? " ",
      comments: comments ?? " ",
    };
    setLoaderState(true);
    try {
      await updateDocument("projects", documentId, projectData, displayName);
      alert ("Successfully edited project.");
    } catch (error) {
      console.log(error);
      alert ("Error editing project.");
    }
    setLoaderState(false);
    setValidated(false);
    setformHasNoChanges(true);
  }

  async function generateProjectReportPdfHandler() {
    setLoaderState(true);
    const generateLeavePdf = httpsCallable(functions, 'generateProjectReportPdf');
    await generateLeavePdf({
      displayName: props.displayName,
      fundCode: fundCode
    })
      .then((response) => {
        setPdfReport(response.data.url[0]);
        setLoaderState(false);
        if (response?.data?.httpErrorCode) {
          alert(response.data.details);
        }
      })
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  async function deleteProj() {
    try {
      setLoaderState(true);
      const result = await deleteProject(fundCode);
      console.log(result);
      setLoaderState(false);
      alert ("Deleted successfully.");
      navigate(`/`);
    } catch (error) {
      console.log(error);
      alert ("Error during deletion.");
      setLoaderState(false);
    };
  }

  async function getInsertConfirmation(isSuccess) {
    if (isSuccess){
      await searchAllocationGroups();
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Project Details
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={editProject}>
        <Form.Group className="mb-3" controlId="editProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="description">
            Desciption:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="text"
            placeholder="Description about the project. (optional)"
            value={description}
            onChange={descriptionListener}
          />
          </InputGroup>

          { isAdmin
            ? <Fragment>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="project-type">
                  Project Type:
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={projectType}
                />
                &nbsp;
                <InputGroup.Text id="project-leader">
                  Project Leader:
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={leader}
                />
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={leader}
                  onSelectedElement={leaderListener}
                  displayName={displayName}
                  elementObjectKey="person"
                  dbName="persons"
                  label="Leader"
                  required={true}
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={leader}
                  label="Leader"
                />
              </Fragment>
            : <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="project-leader">
                Project Leader:
              </InputGroup.Text>
              <Form.Control
                disabled
                type="text"
                value={leader}
              />
              </InputGroup>
          }

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="start-date">
            Start Date:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="date"
            placeholder="Start Date"
            value={startDate}
            inputMode='none'
            onChange={startDateListener}
          />
          <InputGroup.Text id="end-date">
            End Date:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="date"
            placeholder="End Date"
            value={endDate}
            inputMode='none'
            onChange={endDateListener}
          />
          <InputGroup.Text id="original-end-date">
            Original End Date:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="date"
            value={originalEndDate}
            inputMode='none'
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="total-cost">
            Total Cost:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="text"
            placeholder="Project Total Cost. (optional)"
            pattern="^[0-9]+(\.[0-9]+)?$"
            value={totalCost}
            onChange={totalCostListener}
          />
          <Form.Control.Feedback type="invalid">
            Total Cost should be a number.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="comments">
            Comments:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            as="textarea"
            aria-label="With textarea"
            placeholder="Comments about the project. (optional)"
            value={comments}
            onChange={commentsListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="createdBy">
            Created By:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createdBy}
          />
          <InputGroup.Text id="createDate">
            Create Timestamp:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createDate}
          />
          </InputGroup>
          { isAdmin
            ? <center>
                <br></br>
                <Button
                  variant="secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  variant="warning"
                  disabled={formHasNoChanges}
                >
                  Save Changes
                </Button>
                &nbsp;
                { pdfReport
                  ? <Fragment>
                      <a href={pdfReport} target="_blank" rel="noreferrer" download>Download PDF</a>
                      &nbsp;
                    </Fragment>
                  : <Fragment>
                      <Button
                        variant="warning"
                        onClick={generateProjectReportPdfHandler}
                      >
                        Generate PDF report
                      </Button>
                      &nbsp;
                    </Fragment>
                }
                <DeleteConfirmationModal
                  label='Project'
                  message1="All allocation groups, budget items and inventory attached to this group will also be deleted."
                  message2="Are you sure you want to delete this project?"
                  onDeleteConfirmation={deleteProj}
                />
              </center>
            : null
          }

          <br></br>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="totalAllotment">
            Total Allotment:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={totalAllotment}
          />
          <InputGroup.Text id="totalDisbursements">
            Total Disbursements:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={totalDisbursements}
          />
          <InputGroup.Text id="unspentAllotment">
            Unspent Allotment:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={unspentAllotment}
          />
          </InputGroup>

          <Form.Label column="lg">Allocation Groups</Form.Label>
        </Form.Group>
        </Form>

        <ListGroup>
        { allocGroupList.length > 0
          ? allocGroupList.map((row) => (
              <ListGroup.Item
                key={`${row.fundCode}_${row.groupName}`}
                action
                onClick={() => navigate(`/allocgroupdetails/${fundCode}/${row.groupName}/${row.allocationExpenseType}`)}
              >
                <div className="ms-2 me-auto">
                  <b>Name:</b> {row.groupName} &nbsp;
                  { row.allocationExpenseType
                    ? <Fragment><b>Expense Type:</b> {row.allocationExpenseType} &nbsp;</Fragment>
                    : null
                  }
                  { row.allotmentPercentage
                    ? <Fragment><b>Allocation Percentage:</b> {row.allotmentPercentage} &nbsp;</Fragment>
                    : null
                  }
                  <b>Allotment</b>: {row.totalAllotment} &nbsp;
                  { row.totalDisbursements
                    ? <Fragment><b>Disbursements</b>: {row.totalDisbursements} &nbsp;</Fragment>
                    : null
                  }
                  { row.runningBalance
                    ? <Fragment><b>Running Balance</b>: {row.runningBalance} &nbsp;</Fragment>
                    : null
                  }
                  { row.controller
                    ? <Fragment><b>Controller</b>: {row.controller} &nbsp;</Fragment>
                    : null
                  }
                </div>
              </ListGroup.Item>
            ))
          : null
        }
        </ListGroup>
        <br></br>
        { isAdmin
          ? <InsertAllocGroupButtonModal
              fundCode={fundCode}
              displayName={displayName}
              onInsertConfirmation={getInsertConfirmation}
              projectType={projectType}
            />
          : null
        }
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectEdit;
