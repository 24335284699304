function ValidateDropDowns(data, fields) {
  let validated = true;
  for (const field of fields) {
    if (!data[field]) {
      validated = false;
    }
  }
  return validated;
}

export default ValidateDropDowns

