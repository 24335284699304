import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { updateDocument } from "../../components/database/update";
import Spinner from "react-bootstrap/Spinner";
import { deleteDocument } from "../../components/database/delete";
import { BudgetItemTypeEnum, BudgetItemTypeList } from "../../models/budget-item-type";
import DropDownSelection from "../../components/ui-common/DropDownSelection";
import CalculateAllotment from "../../utilities/CalculateAllotment";
import { Fragment } from "react";
import ValidateDropDowns from "../../utilities/ValidateDropDowns";


const DetailsBudgetItemsListModal = (props) => {

  const budgetItem = props.budgetItem;
  const displayName = props.displayName;
  const fundCode = budgetItem.fundCode;
  const groupName = budgetItem.groupName;
  const budgetId = budgetItem.budgetId;
  const allotmentPercentage = props.allotmentPercentage;
  const isAdmin = props.isAdmin;
  const [date, setDate] = useState(budgetItem.date);
  const [docType, setDocType] = useState(budgetItem.docType);
  const [particulars, setParticulars] = useState(budgetItem.particulars);
  const [prNumber , setPrNumber] = useState(budgetItem.prNumber);
  const [poNumber, setPoNumber] = useState(budgetItem.poNumber);
  const [dvNumber, setDvNumber] = useState(budgetItem.dvNumber);
  const [amount, setAmount] = useState(budgetItem.amount);
  const [supplier, setSupplier] = useState(budgetItem.supplier);
  const [claimant, setClaimant] = useState(budgetItem.claimant);
  const [allotment, setAllotment] = useState(budgetItem.allotment);
  const [obligations, setObligations] = useState(budgetItem.obligations);
  const [disbursements, setDisbursements] = useState(budgetItem.disbursements);
  const [formHasNoChanges, setformHasNoChanges] = useState(true);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  function handleClose(status) {
    clearInputs(status);
    setShow(false);
  };
  function handleShow() {
    setShow(true);
  };

  function dateListener(event) {
    setDate(event.target.value);
    setformHasNoChanges(false);
  }
  function particularsListener(event) {
    setParticulars(event.target.value);
    setformHasNoChanges(false);
  }
  function docTypeListener(value) {
    setDocType(value);
    setformHasNoChanges(false);
  }
  function prNumberListener(event) {
    setPrNumber(event.target.value);
    setformHasNoChanges(false);
  }
  function poNumberListener(event) {
    setPoNumber(event.target.value);
    setformHasNoChanges(false);
  }
  function dvNumberListener(event) {
    setDvNumber(event.target.value);
    setformHasNoChanges(false);
  }
  function amountListener(event) {
    setAmount(event.target.value);
    setformHasNoChanges(false);
  }
  function supplierListener(event) {
    setSupplier(event.target.value);
    setformHasNoChanges(false);
  }
  function claimantListener(event) {
    setClaimant(event.target.value);
    setformHasNoChanges(false);
  }
  function allotmentListener(event) {
    setAllotment(event.target.value);
    setformHasNoChanges(false);
  }
  function obligationsListener(event) {
    setObligations(event.target.value);
    setformHasNoChanges(false);
  }
  function disbursementsListener(event) {
    setDisbursements(event.target.value);
    setformHasNoChanges(false);
  }

  async function editBudgetItem(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const budgetData = {
      fundCode: fundCode,
      groupName: groupName,
      budgetId: budgetId,
      date: date,
      docType: docType,
      particulars: particulars,
      amount: amount
    };
    if (!ValidateDropDowns(budgetData, ["docType"])) {
      return;
    }
    // add allotment calclation if docType = release
    if (docType === "RELEASE") {
      const allotment = CalculateAllotment(allotmentPercentage, amount)
      console.log(allotment);
      budgetData.allotment = allotment;
      setAllotment(allotment);
    } else {
      budgetData.prNumber = prNumber;
      budgetData.poNumber = poNumber;
      budgetData.dvNumber = dvNumber;
      budgetData.supplier = supplier;
      budgetData.claimant = claimant;
      budgetData.obligations = obligations;
      budgetData.disbursements = disbursements;
    }
    console.log(budgetData);
    try {
      setLoaderState(true);
      await updateDocument("budget_items", budgetItem.docId, budgetData, displayName);
      setLoaderState(false);
      alert ("Changes were saved successfully.");
      handleClose(true);
    } catch (error) {
      console.log(error);
      alert ("Error during savng of changes.");
      setLoaderState(false);
    };
  }

  // async function addInventoryItem() {
  //   try {
  //     setLoaderState(true);
  //     const inventoryItem = {
  //       fundCode: fundCode,
  //       groupName: groupName,
  //       budgetId: budgetId,
  //       particulars: particulars,
  //       inventoryStatus: InventoryStatusEnum.SERVICABLE,
  //       dateReceived: DateTime.now().toFormat('yyyy-MM-dd')
  //     };
  //     await updateDocument("budget_items", budgetItem.docId, {hasInventory: true}, displayName);

  //     await insertDocument(
  //       "inventory",
  //       inventoryItem,
  //       "",
  //       false,
  //       "",
  //       false,
  //       displayName
  //     );
  //     setLoaderState(false);
  //     alert ("Successfully added inventory. Redirecting you to inventory edit page.");
  //     navigate(`/projectinventorydetails/${fundCode}/${groupName}/${budgetId}`);
  //   } catch (error) {
  //     console.log(error);
  //     setLoaderState(false);
  //     alert ("Error adding inventory.");
  //   };
  // }

  async function deleteBudgetItem() {
    try {
      setLoaderState(true);
      await deleteDocument("budget_items", budgetItem.docId);
      setLoaderState(false);
      alert ("Deleted successfully.");
      handleClose(true);
    } catch (error) {
      console.log(error);
      alert ("Error during deletion.");
      setLoaderState(false);
    };
  }

  function clearInputs(status) {
    setValidated(false);
    props.onEditDeleteConfirmation(status);
  }


  return (
    <React.Fragment>
      <ListGroup.Item
        key={`${budgetItem.budgetId}`}
        action
        onClick={handleShow}
      >
        <div className="ms-2 me-auto">
          <b>Date:</b> {budgetItem.date} &nbsp;
          <b>Type:</b> {budgetItem.docType} &nbsp;
          <b>Particulars:</b> {budgetItem.particulars} &nbsp;
          <b>Amount</b>: {budgetItem.amount} &nbsp;
          { budgetItem.allotment
            ? <Fragment><b>Allotment</b>: {budgetItem.allotment} &nbsp;</Fragment>
            : null
          }
          { budgetItem.obligations
            ? <Fragment><b>Obligations</b>: {budgetItem.obligations} &nbsp;</Fragment>
            : null
          }
          { budgetItem.disbursements
            ? <Fragment><b>Disbursements</b>: {budgetItem.disbursements} &nbsp;</Fragment>
            : null
          }
          { budgetItem.hasInventory
            ? <Fragment><font color="#B1351F"><b>Budget Item Has Inventory</b></font></Fragment>
            : null
          }

        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={() => (handleClose(false))}
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Edit {budgetItem.particulars}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        <Form noValidate validated={validated} onSubmit={editBudgetItem}>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="date">Date:</InputGroup.Text>
            <Form.Control
              disabled={!isAdmin}
              required
              type="date"
              placeholder={date}
              value={date}
              onChange={dateListener}
            />
            <InputGroup.Text id="docType">Type:</InputGroup.Text>
            <DropDownSelection
              disabled={true}
              label="type"
              validated={validated}
              required={true}
              dropDownList={BudgetItemTypeList}
              dropDownListener={docTypeListener}
              selectedElement={docType}
            />
            <Form.Control.Feedback type="invalid">
              Date and Type should not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="particulars">Particulars:</InputGroup.Text>
            <Form.Control
              disabled={!isAdmin}
              required
              type="text"
              placeholder={particulars}
              value={particulars}
              onChange={particularsListener}
            />
            <Form.Control.Feedback type="invalid">
              Particulars should not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="amount">Amount:</InputGroup.Text>
            <Form.Control
              disabled={!isAdmin}
              required
              type="text"
              placeholder={amount}
              pattern="^[0-9]+(\.[0-9]+)?$"
              value={amount}
              onChange={amountListener}
            />
            <Form.Control.Feedback type="invalid">
              Amount should be a number and not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            { allotment
              ? <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="allotment">Allotment:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  pattern="^[0-9]+(\.[0-9]+)?$"
                  value={allotment}
                  onChange={allotmentListener}
                />
                </InputGroup>
              : null
            }

            { docType !== BudgetItemTypeEnum.RELEASE
              ? <Fragment>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="obligations">Obligations:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="Obligations"
                  pattern="^[0-9]+(\.[0-9]+)?$"
                  value={obligations}
                  onChange={obligationsListener}
                />
                <Form.Control.Feedback type="invalid">
                  Obligations should be a number.
                </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="disbursements">Disbursements:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="Disbursements"
                  pattern="^[0-9]+(\.[0-9]+)?$"
                  value={disbursements}
                  onChange={disbursementsListener}
                />
                <Form.Control.Feedback type="invalid">
                  Disbursements should be a number.
                </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="prNumber">PR Number:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="PR Number (optional)"
                  value={prNumber}
                  onChange={prNumberListener}
                />
                <InputGroup.Text id="poNumber">PO Number:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="PO Number (optional)"
                  value={poNumber}
                  onChange={poNumberListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="dvNumber">DV Number:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="DV Number (optional)"
                  value={dvNumber}
                  onChange={dvNumberListener}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="supplier">Supplier:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  value={supplier}
                  placeholder="Supplier (optional)"
                  onChange={supplierListener}
                />
                <InputGroup.Text id="claimant">Claimant:</InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  value={claimant}
                  placeholder="Claimant (optional)"
                  onChange={claimantListener}
                />
                </InputGroup>
                </Fragment>
              : null
            }

          </Form.Group>

          { loaderState
            ? <center>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </center>
            : <center>
                <Button
                  variant="secondary"
                  onClick={() => (handleClose(false))}
                  style={{marginTop:"10px"}}
                >
                  Cancel
                </Button>
                { isAdmin
                  ? <Fragment>
                      &nbsp;
                      <Button
                        disabled={formHasNoChanges}
                        variant="warning"
                        type="submit"
                        style={{marginTop:"10px"}}
                      >
                        Save and Close
                      </Button>
                      &nbsp;
                      <Button
                        variant="danger"
                        onClick={deleteBudgetItem}
                        style={{marginTop:"10px"}}
                      >
                        Delete Item
                      </Button>
                    </Fragment>
                  : null
                }
              </center>
          }
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DetailsBudgetItemsListModal

