import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import { updateDocument } from "../database/update";
import { deleteDocument } from "../database/delete";
import { Fragment } from "react";


const DetailsDataListModal = (props) => {

  const docId = props.docId;
  const label = props.label;
  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const elementObjectDescKey = props.elementObjectDescKey;
  const [element, setElement] = useState(props.element);
  const [elementDescription, setElementDescription] = useState(props.elementDescription);
  const onSuccessConfirmation = props.onSuccessConfirmation;
  const isAdmin = checkIsAdmin(props.roles);

  const [show, setShow] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const displayName = props.displayName;

  function checkIsAdmin(roles) {
    let isAdmin = false;
    for (const role of roles) {
      if (role.includes("ADMIN")) {
        isAdmin = true;
      }
    }
    return isAdmin;
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function elementListener(event) {
    setElement(event.target.value.toUpperCase());
  }
  function elementDescriptionListener(event) {
    setElementDescription(event.target.value);
  }

  async function editElement() {
    const elementData = {
      [elementObjectKey]: element,
      [elementObjectDescKey]: elementDescription
    };
    setLoaderState(true);
    const insertSuccess = await updateDocument(dbName, docId, elementData, displayName);
    if (!insertSuccess){
      return;
    }
    setLoaderState(false);
    onSuccessConfirmation(true);
    setElement(element);
    setShow(false);
  }

  async function deleteElement() {
    try {
      setLoaderState(true);
      await deleteDocument(dbName, docId);
      alert ("Deleted successfully.");
      onSuccessConfirmation(true);
      handleClose(true);
    } catch (error) {
      console.log(error);
      alert ("Error during deletion.");
    };
    setLoaderState(false);
  }


  return (
    <React.Fragment>
      <ListGroup.Item key={element} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>{label}</b>: {element}
          &nbsp; <b>{label} Description:</b> {elementDescription}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>
            { isAdmin
              ? <Fragment>Edit {label}</Fragment>
              : <Fragment>{label}</Fragment>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6",
            color:"#fff"
          }}
        >
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">{label}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label}
              value={element}
              onChange={elementListener}
              disabled={true}
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="itemTypeDescription">{label} Description</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label.concat(" Description")}
              value={elementDescription}
              onChange={elementDescriptionListener}
              disabled={!isAdmin}
            />
            </InputGroup>
          </Form.Group>
          </Form>
          <center>
            { loaderState
              ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              : <Fragment>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  { isAdmin
                    ? <Fragment>
                        &nbsp;
                        <Button
                          variant="warning"
                          onClick={editElement}
                        >
                          Save and Exit
                        </Button>
                        &nbsp;
                        <Button
                          variant="danger"
                          onClick={deleteElement}
                        >
                          Delete and Exit
                        </Button>
                      </Fragment>
                    : null
                  }
                </Fragment>
            }
          </center>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6",
            color:"#fff"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DetailsDataListModal

