import { db } from '../../firebaseConfig';
import { collection, getDocs, doc, setDoc, query, where, Timestamp } from 'firebase/firestore/lite';


export const insertDocument = async (
    collectionName,
    insertData,
    uniqueKey,
    isDocIdUniqueKey,
    uniqueKeyFilters,
    uniqueCheck,
    contextUser
  ) => {

  const collectionRef = collection(db, collectionName);

  let docsSnapshot;
  if (uniqueCheck) {
    try {
      const queryFilters = uniqueKeyFilters.map((searchFilter) => where(searchFilter.key, "==", searchFilter.inputValue));
      const q = query(collectionRef, ...queryFilters);
      docsSnapshot = await getDocs(q);
    } catch (error) {
      console.log(error);
      alert (`DB error encountered: ${error}`);
      return false;
    }

    if (docsSnapshot.docs.length > 0) {
      alert('Record already exists, please use a different name or edit the existing one.')
      return false;
    }
  }
  insertData.createdBy = contextUser;
  insertData.createDate = Timestamp.now();

  try {
    if (isDocIdUniqueKey) {
      await setDoc(doc(collectionRef, insertData[uniqueKey]), insertData);
    } else {
      await setDoc(doc(collectionRef), insertData);
    }
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
