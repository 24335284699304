import React, { useState } from "react";
import CustomInputValidation from "./CustomInputValidation";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';


const DropDownSelection = (props) => {

  const label = props.label;
  const validated = props.validated;
  const disabled = props.disabled;
  const required = props.required;
  const dropDownList = props.dropDownList;
  const [selectedElement, setSelectedElement] = useState(props.selectedElement ?? "");

  function dropDownSelectListener(value) {
    setSelectedElement(value);
    props.dropDownListener(value)
  }

  return (
    <React.Fragment>
      <Dropdown
        id="dropdown-button"
        as={Form.Control}
        value={selectedElement}
        required={required}
      >
      <Dropdown.Toggle variant="warning">
        {selectedElement || `Select a ${label}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          dropDownList.map((val) => (
            <Dropdown.Item
              disabled={disabled}
              key={val}
              onClick={() => {dropDownSelectListener(val)}}
            >
              {val}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      <CustomInputValidation
        validated={validated}
        required={required}
        evaluatedValue={selectedElement}
        label={label}
      />
      </Dropdown>
      <Form.Control.Feedback>
      {(!selectedElement && validated) && <div className="text-danger">Please select a {label}.</div>}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default DropDownSelection
