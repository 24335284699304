function ValidateKeyInputs (inputObject, keyField) {

  const invalidChar = "/";

  let result = true;
  if (inputObject[keyField].includes(invalidChar)) {
    result = false;
  }
  return result;

}


export default ValidateKeyInputs

