import React from "react";
import { Check, ExclamationCircle } from "react-bootstrap-icons";


const CustomInputValidation = (props) => {
  return (
      props.validated
      ? <React.Fragment>
        { props.evaluatedValue
          ? <React.Fragment>
            &nbsp;
            <Check
              color="green"
            />
            </React.Fragment>
          : <React.Fragment>
            { props.required
              ? <React.Fragment>
                &nbsp;
                <ExclamationCircle
                  color="red"
                />
                </React.Fragment>
              : <React.Fragment>
                &nbsp;
                <Check
                  color="green"
                />
                </React.Fragment>
            }
            </React.Fragment>
        }
        </React.Fragment>
      : null
  )
}

export default CustomInputValidation;
