import React, { useState, useEffect, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../database/insert";
import { listDocuments } from "../database/get";
import CustomInputValidation from "../ui-common/CustomInputValidation";
import { Spinner } from "react-bootstrap";


const InsertDataDropdownModal = (props) => {

  const label = props.label;
  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const elementDescriptionObjectKey = `${props.elementObjectKey}Description`;
  const [element, setElement] = useState('');
  const [elementDescription, setElementDescription] = useState('');

  const [show, setShow] = useState(false);
  const [elementList, setElementList] = useState([]);
  const [selectedElement, setSelectedElement] = useState(props.selectedElement);
  const [loaderState, setLoaderState] = useState(false);
  const displayName = props.displayName;

  function handleClose() {
    setElement("");
    setElementDescription("");
    setShow(false);
  };

  useEffect(() => {
    getElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.selectedElement]);

  async function getElements() {
    console.log("CALLED");
    const elementList = await listDocuments(dbName, elementObjectKey, "asc");
    elementList.push({[elementObjectKey]: `Create New ${label}`});
    setElementList(elementList);
  }

  function elementDropDownListener(elementInput) {
    if (elementInput === `Create New ${label}`) {
      setShow(true);
    } else {
      setSelectedElement(elementInput);
      props.onSelectedElement(elementInput);
      handleClose();
    }
  }

  function elementListener(event) {
    setElement(event.target.value.toUpperCase());
  }

  function elementDescriptionListener(event) {
    setElementDescription(event.target.value);
  }

  async function createNewElement() {
    if (!validateInputs()) {
      alert(`${label} is required`);
      return;
    }
    const elementData = {
      [elementObjectKey]: element,
      [elementDescriptionObjectKey]: elementDescription
    };
    const uniqueKeyFilter = [
      { key: elementObjectKey, inputValue: element}
    ];
    setLoaderState(true);
    const insertSuccess = await insertDocument(
      dbName,
      elementData,
      elementObjectKey,
      false,
      uniqueKeyFilter,
      true,
      displayName
    );
    if (!insertSuccess){
      return;
    }
    setLoaderState(false);
    setElement(element);
    setSelectedElement(element);
    props.onSelectedElement(element);
    handleClose();
  }

  function validateInputs() {
    if (!element) {
      return false;
    }
    return true;
  }


  return (
    <React.Fragment>
      <Dropdown
        id="dropdown-button"
        as={Form.Control}
        value={selectedElement}
        >
      <Dropdown.Toggle variant="warning">
        {selectedElement || `Select a ${label}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          elementList.map((val) => (
            <Dropdown.Item
              key={val[elementObjectKey]}
              onClick={() => {elementDropDownListener(val[elementObjectKey])}}>
                {val[elementObjectKey]}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      <CustomInputValidation
        validated={props.validated}
        required={props.required}
        evaluatedValue={props.selectedElement}
        label={props.label}
      />
      </Dropdown>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Create New {label}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">{label}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label}
              value={element}
              onChange={elementListener}
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="elementeDescription">{label} Description</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label.concat(" Description")}
              value={elementDescription}
              onChange={elementDescriptionListener}
            />
            </InputGroup>
          </Form.Group>
          </Form>
          <center>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            : <Fragment>
              <Button
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                variant="warning"
                onClick={createNewElement}
              >
                Create
              </Button>
            </Fragment>
          }
          </center>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertDataDropdownModal

