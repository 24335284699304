import * as React from "react";
import MainHeader from "../../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./UserProfile.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../../components/ui-common/CustomLoader";
import { db, auth } from "../../firebaseConfig";
import { collection, getDoc, doc } from 'firebase/firestore/lite';
import { sendPasswordResetEmail } from "firebase/auth"
import Button from "react-bootstrap/Button";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Footer from '../../components/navigation/Footer';


const UserProfile = () => {

  // User list state
  const { user } = useAuthContext();
  const [displayNameContext, setDisplayNameContext] = useState(user?.displayName);
  const [rolesContext, setRolesContext] = useState([]);

  const [userPofile, setUserProfile] = useState();
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayNameContext(tokenResult.claims?.name);
          setRolesContext(parsedUserRoles);
          getUser(tokenResult.claims?.name);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(tokenResult) {
              const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
              setDisplayNameContext(tokenResult.claims?.name);
              setRolesContext(parsedUserRoles);
              getUser(tokenResult.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  async function getUser(displayName) {
    // Enable loader when request is being sent.
    setLoaderState(true);

    const usersRef = collection(db, "users");
    const documentRef = doc(usersRef, displayName);
    const document = await getDoc(documentRef);
    setUserProfile(document.data());
    setLoaderState(false);
  }

  async function resetPassword(email) {
    setLoaderState(true);
    return sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
    });
  }

  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
      <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayNameContext}
        roles={rolesContext}
      />
        <center>
        <h4 style={{marginTop:20}}>User Profile</h4>
        <ul className={classes.viewBox}>
        { userPofile
          ? <React.Fragment>
              <label>
                <b>Display Name:</b> {userPofile.displayName} <br></br>
                <b>Email:</b> {userPofile.email} <br></br>
                <b>First Name:</b> {userPofile.firstName} <br></br>
                <b>Last Name:</b> {userPofile.lastName} <br></br>
                <b>Roles:</b>
              </label>

              {
                userPofile.roles.map((role, index) => (
                  <div key={`${role}-${index}`}>
                  <label> {role} </label>
                  </div>
                ))
              }
            </React.Fragment>
          : null
        }
        <br></br>
        <br></br>
        <Button variant="warning" onClick={() => resetPassword(userPofile.email)}>
          Reset My Account Password
        </Button>
        </ul>
        </center>
        <Footer />
      </div>
    </CustomLoader>
  );
}

export default UserProfile;
