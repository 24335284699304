import { db } from '../../firebaseConfig';
import { collection, deleteDoc, doc, getDocs, query, where, writeBatch } from 'firebase/firestore/lite';


export const deleteDocument = async (collectionName, documentId) => {
  const collectionRef = collection(db, collectionName);

  try {
    const docRef = doc(collectionRef, documentId);
    await deleteDoc(docRef);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};

export const deleteAllocationGroup = async (fundCode, groupName) => {
  const budgetItemsRef = collection(db, "budget_items");

  const batch = writeBatch(db);
  let budgetItemsDocsSnapshot;
  try {
    const q = query(
      budgetItemsRef,
      where("fundCode",'==',fundCode),
      where("groupName",'==',groupName)
    );
    budgetItemsDocsSnapshot = await getDocs(q);
  } catch (error) {
    console.log(error);
    alert (`DB error encountered: ${error}`);
    return false;
  }

  budgetItemsDocsSnapshot.forEach((doc) => {
    // For each document, add a delete operation to the batch
    const docRef = doc.ref;
    batch.delete(docRef);
  });

  const allocationGroupRef = collection(db, "allocation_groups");
  let allocGroupDocsSnapshot;
  try {
    const q = query(
      allocationGroupRef,
      where("fundCode",'==',fundCode),
      where("groupName",'==',groupName)
    );
    allocGroupDocsSnapshot = await getDocs(q);
  } catch (error) {
    console.log(error);
    alert (`DB error encountered: ${error}`);
    return false;
  }

  allocGroupDocsSnapshot.forEach((doc) => {
    // For each document, add a delete operation to the batch
    const docRef = doc.ref;
    batch.delete(docRef);
  });
  // Commit the batch to delete the documents
  batch.commit();

  return true;
};

export const deleteProject = async (fundCode) => {
  const projectRef = collection(db, "projects");

  let projectsDocsSnapshot;
  try {
    const q = query(
      projectRef,
      where("fundCode",'==',fundCode)
    );
    projectsDocsSnapshot = await getDocs(q);
  } catch (error) {
    console.log(error);
    alert (`DB error encountered: ${error}`);
    return false;
  }

  projectsDocsSnapshot.forEach((doc) => {
    // For each document, add a delete operation to the batch
    const docRef = doc.ref;
    deleteDoc(docRef);
  });

  const allocationGroupRef = collection(db, "allocation_groups");
  let allocGroupDocsSnapshot;
  try {
    const q = query(
      allocationGroupRef,
      where("fundCode",'==',fundCode)
    );
    allocGroupDocsSnapshot = await getDocs(q);
  } catch (error) {
    console.log(error);
    alert (`DB error encountered: ${error}`);
    return false;
  }
  allocGroupDocsSnapshot.forEach((doc) => {
    deleteAllocationGroup(fundCode, doc.data().groupName);
  });

  return true;
};
