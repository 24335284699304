import MainHeader from '../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from './CreateUser.module.css';
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/ui-common/CustomLoader';
import { functions } from '../../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { UserRolesList } from '../../models/user-roles';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { X } from "react-bootstrap-icons";
import { useAuthContext } from '../../components/context/useAuthContext';
import firebase from 'firebase/compat/app';
import Footer from '../../components/navigation/Footer';
import RolesChecker from '../../utilities/RolesChecker';


const CreateUser = (props) => {

  const { user } = useAuthContext();
  const [displayNameContext, setDisplayNameContext] = useState(user?.displayName);
  const [rolesContext, setRolesContext] = useState([]);
  // Display Name input form listener
  const [displayName, setDisplayName] = useState('');
  // Regex for display name validation
  const displayNameRegex = new RegExp('[\\s]');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // Regex for password (minimum 8 chars)
  const passwordRegex = new RegExp('(?=.{8,})');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [roles, setRoles] = useState([]);

  const [userRolesList, setUserRolesList] = useState([]);

  // Create user Result
  const [createResult, setCreateResult] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
            alert('Unauthorized access.');
            navigate('/');
          }
          setDisplayNameContext(tokenResult.claims?.name);
          setRolesContext(parsedUserRoles);
          if (parsedUserRoles.find(role => role === "SUPERADMIN")) {
            setUserRolesList(UserRolesList);
          } else {
            const filteredRoles = UserRolesList.filter(role => role !== "ADMIN" && role !== "SUPERADMIN");
            setUserRolesList(filteredRoles);
          }
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(tokenResult) {
              const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setDisplayNameContext(tokenResult.claims?.name);
              setRolesContext(parsedUserRoles);
              if (parsedUserRoles.find(role => role === "SUPERADMIN")) {
                setUserRolesList(UserRolesList);
              } else {
                const filteredRoles = UserRolesList.filter(role => role !== "ADMIN" && role !== "SUPERADMIN");
                setUserRolesList(filteredRoles);
              }
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  // Input Listeners
  function displayNameListener(event) {
    setDisplayName(event.target.value);
  }
  function emailListener(event) {
    setEmail(event.target.value);
  }
  function passwordListener(event) {
    setPassword(event.target.value);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }

  function resetInputFields() {
    setDisplayName('');
    setEmail('');
    setPassword('');
    setPhoneNumber('');
    setFirstName('');
    setLastName('');
  }

  // triggering search when button is pressed
  async function createUser(event) {
    event.preventDefault();
    if (roles.length === 0) {
      alert("Please select a role for the user.");
      return;
    }
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    setCreateResult('');
    const data = {
      displayName: displayName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
      firstName: firstName,
      lastName: lastName,
      roles: roles
    };
    if (!data.phoneNumber) {
      delete data.phoneNumber;
    }
    if (displayNameRegex.test(displayName) || !displayName) {
      alert(`Display Username is required and must not contain a SPACE.`);
      return;
    }
    // if not less than 8 characters
    if (!passwordRegex.test(password)) {
      alert(`Password must be 8-16 characters long.`);
      return;
    }
    // Enable loader when request is being sent.
    setLoaderState(true);

    const createUser = httpsCallable(functions, 'createUser');
    // console.log(data);
    await createUser(
      data
    ).then((response) => {
        console.log(response);
        // Disable loader when results are in
        setLoaderState(false);
        if (response?.data?.httpErrorCode) {
          alert(`Encountered an error: email ${response.data.httpErrorCode.canonicalName}`);
        } else {
          // Action to trigger after success.
          setCreateResult(response.data);
          resetInputFields();
        }
        setValidated(false);
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        setValidated(false);
        alert(error);
      });
  }


  function addRoleHandler(role) {
    const tempRoles = [].concat(roles);
    tempRoles.push(role);
    removeRoles(role);
    setRoles(tempRoles);
  }

  function removeRoles(role) {
    const tempRolesList = [].concat(userRolesList);
    let deleteIndex = tempRolesList.indexOf(role);
    if (deleteIndex !== -1) {
      tempRolesList.splice(deleteIndex, 1);
    }
    setUserRolesList(tempRolesList);
  }

  function removeRoleHandler(index) {
    const tempRoles = [].concat(roles);
    tempRoles.splice(index, 1);
    setRoles(tempRoles);
    setUserRolesList(removeDuplicateRoles);
  }

  function removeDuplicateRoles() {
    const tempRolesList = [].concat(UserRolesList);
    roles.forEach(function(role) {
      let deleteIndex = tempRolesList.indexOf(role);
      if (deleteIndex !== -1) {
        tempRolesList.splice(deleteIndex, 1);
      }
    });
    return tempRolesList;
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayNameContext}
        roles={rolesContext}
      />
      <center>
        <h3 style={{marginTop:15}}>Create User</h3>
        { createResult
          ? <div className={classes.resultContainer}>
            <h3> Successfully created the following user:</h3>
            <li><label><b>Display Name:</b> {createResult.displayName} </label></li>
            <li><label><b>Email:</b> {createResult.email} </label></li>
            <li><label><b>First Name:</b> {createResult.firstName}</label></li>
            <li><label><b>Last Name:</b> {createResult.lastName}</label></li>
            <li><label><b>Role: </b></label></li>
            {
              createResult.roles.map((role) => (
                <li><label> {role} </label></li>
              ))
            }
            { createResult.phoneNumber
              ? <li><label>Phone Number: {createResult.phoneNumber} </label></li>
              : null
            }
            </div>
          : <div className={classes.mainInputContainer}>
            <Form noValidate validated={validated} onSubmit={createUser}>
              <Form.Group className="mb-3" controlId="createUser">

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="displayName">Display Username</InputGroup.Text>
                  <Form.Control
                    required
                    pattern="^(?!.*\s)[a-zA-Z0-9]+$"
                    type="text"
                    placeholder="Unique username use this format FirstnameLastname e.g. JuanDelaCruz"
                    value={displayName}
                    onChange={displayNameListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Must not be blank, no spaces and no special characters.
                  </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="email">Email</InputGroup.Text>
                  <Form.Control
                    required
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    type="text"
                    placeholder="user_email@another.com"
                    value={email}
                    onChange={emailListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email must not be blank and must be in proper email format.
                  </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="password">Password</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    value={password}
                    onChange={passwordListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Password must not be blank.
                  </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="phoneNumber">Phone Number (optional)</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="\+63\d{10}"
                    placeholder="+639xx xxx xxxx"
                    value={phoneNumber}
                    onChange={phoneNumberListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Cellphone number must follow international format +63xxxxxxxxxx.
                  </Form.Control.Feedback>
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="firstName">First Name</InputGroup.Text>
                  <Form.Control
                    type="text" value={firstName}
                    onChange={firstNameListener}
                    placeholder="Juan"
                  />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="lastName">Last Name</InputGroup.Text>
                  <Form.Control
                    type="text" value={lastName}
                    onChange={lastNameListener}
                    placeholder="Dela Cruz"
                  />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="role">Role</InputGroup.Text>
                {
                  roles.map((role, index) => (
                    <div key={`${role}-${index}`} style={{marginBottom:"3px"}}>
                      <Button key={`${role}-${index}`} variant="warning" disabled>
                        {role}
                      </Button>
                      <X key={`x-${role}`} onClick={() => removeRoleHandler(index)}
                      />
                      &nbsp;&nbsp;
                    </div>
                  ))
                }
                <DropdownButton
                  variant="warning"
                  key="role"
                  title={"ADD ROLE"}
                >
                {
                  userRolesList.map((val) => (
                    <Dropdown.Item key={val} onClick={() => {addRoleHandler(val)}}>
                      {val}
                    </Dropdown.Item>
                  ))
                }
                </DropdownButton>
                </InputGroup>
                <small style={{color:"white"}}>
                  *Display Username and Email cannot be <br></br>
                  changed once the user has been created. <br></br>
                  *Follow this rule: firstname+lastname for Display Username <br></br>
                  *example: firstname: Juan lastname: Dela Cruz = JuanDelaCruz <br></br>
                </small>
                <br></br>
                <Button
                  variant="warning"
                  type="submit"
                >
                  Create User
                </Button>
              </Form.Group>
            </Form>
            </div>
        }
      </center>
      <Footer />
    </div>
    </CustomLoader>
  );
};

export default CreateUser;
