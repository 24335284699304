// import logo from './logo.svg';
import './App.css';
import React from 'react';
import SignIn from './pages/SignIn';


function App()
{

  return (
    <div>
      <center>
      <SignIn />
      </center>
    </div>
  );

}

export default App;
