import { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import classes from './SideBar.module.css';
import Badge from 'react-bootstrap/Badge';
import MenuLinks from './MenuLinks';


const SideBar = (props) => {
  // Sidebar open and close state
  const [show, setShow] = useState(false);
  const [userSubMenuShow, setUserSubMenuShow] = useState(false);
  const [adminSubMenuShow, setAdminSubMenuShow] = useState(false);
  const [superAdminSubMenuShow, setSuperAdminSubMenuShow] = useState(false);

  const [budgetAdminMenuShow, setBudgetAdminMenuShow] = useState(false);
  const [budgetViewerMenuShow, setBudgetViewerMenuShow] = useState(false);

  const [inventoryAdminMenuShow, setInventoryAdminMenuShow] = useState(false);
  const [inventoryViewerMenuShow, setInventoryViewerMenuShow] = useState(false);

  const displayName = props.displayName;
  const roles = props.roles;
  const navigate = useNavigate();

  function handleClose () {
    setShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setBudgetAdminMenuShow(false);
    setBudgetViewerMenuShow(false);
    setInventoryAdminMenuShow(false);
    setInventoryViewerMenuShow(false);
  }
  function handleShow () {
    setShow(true)
  };
  function toggleInventoryAdminMenuShow() {
    setInventoryAdminMenuShow(!inventoryAdminMenuShow);
    setInventoryViewerMenuShow(false);
    setBudgetAdminMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleInventoryViewerMenuShow() {
    setInventoryViewerMenuShow(!inventoryViewerMenuShow);
    setInventoryAdminMenuShow(false);
    setBudgetAdminMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleBudgetAdminMenuShow() {
    setBudgetAdminMenuShow(!budgetAdminMenuShow);
    setInventoryViewerMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setInventoryAdminMenuShow(false);
  }
  function toggleBudgetViewerMenuShow() {
    setBudgetViewerMenuShow(!budgetViewerMenuShow);
    setBudgetAdminMenuShow(false);
    setInventoryViewerMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setInventoryAdminMenuShow(false);
  }
  function toggleUserSubMenu() {
    setUserSubMenuShow(!userSubMenuShow);
    setInventoryViewerMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setBudgetAdminMenuShow(false);
    setInventoryAdminMenuShow(false);
  }
  function toggleAdminSubMenu() {
    setAdminSubMenuShow(!adminSubMenuShow);
    setInventoryViewerMenuShow(false);
    setUserSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setBudgetAdminMenuShow(false);
    setInventoryAdminMenuShow(false);
  }
  function toggleSuperAdminSubMenu() {
    setSuperAdminSubMenuShow(!superAdminSubMenuShow);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setBudgetAdminMenuShow(false);
  }

  return (
    <Fragment>
      <Button variant="warning" onClick={handleShow} size="lg" >
        MENU &nbsp; <i className="fa-solid fa-bars fa-xl"></i>
      </Button>
      <div style={{margin: 10}}>
        { roles.length > 0
          ? roles.map((role) => (
              <Badge
                pill
                bg="warning"
                text="dark"
                style={{marginRight: "4px"}}
              >
                {role}
              </Badge>
            ))
          : null
        }
        <Badge
          pill
          bg="success"
          text="light"
          onClick={() => navigate('/userprofile')}
        >
          {displayName}
        </Badge>
      </div>
      <Offcanvas show={show} onHide={handleClose} style={
        {
          backgroundColor:"#064422",
          color: "whitesmoke",
          width: 200
        }
      }>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={classes.linkList}>
            <li>
              <Link to='/home'>
                <Button variant="warning">
                  Home
                </Button>
              </Link>
            </li>

            <li>
              { roles.find(role => role === "ADMIN"
                || role === "SUPERADMIN"
                || role === "BUDGETADMIN"
              )
                ? <Button variant="warning" onClick={toggleBudgetAdminMenuShow}>
                    Project
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={budgetAdminMenuShow}
              objectLinksList={[
                {label:"Create Project", link:"/projectCreate"},
                {label:"Search and Edit Project", link:"/projectSearch"},
                {label:"Group", link:"/group"},
                {label:"Person", link:"/person"},
                {label:"Expense Type", link:"/allocationexpensetype"},
              ]}
            />

            <li>
              { roles.find(role => role === "BUDGETVIEWER")
                ? <Fragment>
                  { roles.find(role => role === "BUDGETADMIN")
                    ? null
                    : <Button variant="warning" onClick={toggleBudgetViewerMenuShow}>
                        Project
                      </Button>
                  }
                  </Fragment>
                : null
              }
            </li>
            <MenuLinks
              collapse={budgetViewerMenuShow}
              objectLinksList={[
                {label:"Search Project", link:"/projectSearch"},
                {label:"Group", link:"/group"},
                {label:"Person", link:"/person"},
                {label:"Expense Type", link:"/allocationexpensetype"},
              ]}
            />

            <li>
              <Button variant="warning" onClick={toggleUserSubMenu}>
                User
              </Button>
            </li>
            <MenuLinks
              collapse={userSubMenuShow}
              objectLinksList={[
                {label:"User Profile", link:"/userprofile"},
              ]}
            />

            <li>
              { roles.find(role => role === "ADMIN" || role === "SUPERADMIN" )
                ? <Button variant="warning" onClick={toggleAdminSubMenu}>
                    Admin
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={adminSubMenuShow}
              objectLinksList={[
                {label:"Create User", link:"/createuser"},
                {label:"Manage Users", link:"/manageusers"},
              ]}
            />

            <li>
              <Link to='/signout'>
              <Button variant="warning">
                Sign Out
              </Button>
            </Link>
            </li>

          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default SideBar;
