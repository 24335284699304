import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


const DeleteConfirmationModal = (props) => {

  const label = props.label;
  const message1 = props.message1;
  const message2 = props.message2;

  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
  };
  function handleShow() {
    setShow(true);
  };

  function confirmDelete() {
    props.onDeleteConfirmation(true);
    handleClose();
  }


  return (
    <React.Fragment>
      <Button
        variant="danger"
        onClick={handleShow}
      >
          Delete {label}
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={() => (handleClose())}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Delete {label}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
          <center>
            <h4>{message1}</h4>
            <h5>{message2}</h5>
          </center>
          <center>
            <Button
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="danger"
              onClick={confirmDelete}
            >
              Confirm Delete
            </Button>
          </center>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DeleteConfirmationModal

