function CheckIsAdmin(roles) {
  let isAdmin = false;
  for (const role of roles) {
    if (role.includes("ADMIN")) {
      isAdmin = true;
    }
  }
  return isAdmin;
}


export default CheckIsAdmin

