import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { BudgetItemTypeEnum } from "../../models/budget-item-type";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { InventoryStatusEnum } from "../../models/inventory-status";
import { updateDocument } from "../../components/database/update";
import { insertDocument } from "../../components/database/insert";


const DetailsBudgetItemsListInventoryModal = (props) => {

  const budgetItem = props.budgetItem;
  const displayName = props.displayName;
  const fundCode = budgetItem.fundCode;
  const groupName = budgetItem.groupName;
  const budgetId = budgetItem.budgetId;
  const hasInventory = budgetItem.hasInventory ?? false;
  const date = budgetItem.date;
  const docType = budgetItem.docType;
  const particulars = budgetItem.particulars;
  const prNumber = budgetItem.prNumber;
  const poNumber = budgetItem.poNumber;
  const dvNumber = budgetItem.dvNumber;
  const amount = budgetItem.amount;
  const supplier = budgetItem.supplier;
  const claimant = budgetItem.claimant;

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  function handleClose() {
    setShow(false);
  };
  function handleShow() {
    setShow(true);
  };

  async function addInventoryItem() {
    try {
      const inventoryItem = {
        fundCode: fundCode,
        groupName: groupName,
        budgetId: budgetId,
        particulars: particulars,
        inventoryStatus: InventoryStatusEnum.SERVICABLE,
        dateReceived: DateTime.now().toFormat('yyyy-MM-dd')
      };
      await updateDocument("budget_items", budgetItem.docId, {hasInventory: true}, displayName);
      await insertDocument(
        "inventory",
        inventoryItem,
        "",
        false,
        "",
        false,
        displayName
      );
      alert ("Successfully added inventory. Redirecting you to inventory edit page.");
      navigate(`/projectinventorydetails/${fundCode}/${groupName}/${budgetId}`);
    } catch (error) {
      console.log(error);
      alert ("Error adding inventory.");
    };
  }


  return (
    <React.Fragment>
      <ListGroup.Item
        key={`${budgetItem.budgetId}`}
        action
        onClick={handleShow}
      >
        <div className="ms-2 me-auto">
          <b>Date:</b> {budgetItem.date} &nbsp;
          <b>Type:</b> {budgetItem.docType} &nbsp;
          <b>Particulars:</b> {budgetItem.particulars} &nbsp;
          <b>Amount</b>: {budgetItem.amount} &nbsp;
          { budgetItem.hasInventory
            ? <Fragment><font color="#B1351F"><b>Budget Item Has Inventory</b></font></Fragment>
            : null
          }

        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={() => (handleClose(false))}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Create Inventory for {budgetItem.particulars}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        <Form>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="date">Date:</InputGroup.Text>
            <Form.Control
              disabled
              type="date"
              value={date}
            />
            <InputGroup.Text id="docType">Type:</InputGroup.Text>
            <Form.Control
              disabled
              type="text"
              value={docType}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="particulars">Particulars:</InputGroup.Text>
            <Form.Control
              disabled
              type="text"
              value={particulars}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="amount">Amount:</InputGroup.Text>
            <Form.Control
              disabled
              type="text"
              value={amount}
            />
            <Form.Control.Feedback type="invalid">
              Amount should be a number and not be blank.
            </Form.Control.Feedback>
            </InputGroup>

            { docType !== BudgetItemTypeEnum.RELEASE
              ? <Fragment>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="prNumber">PR Number:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={prNumber}
                />
                <InputGroup.Text id="poNumber">PO Number:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={poNumber}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="dvNumber">DV Number:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={dvNumber}
                />
                </InputGroup>

                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="supplier">Supplier:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={supplier}
                />
                <InputGroup.Text id="claimant">Claimant:</InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={claimant}
                />
                </InputGroup>
                </Fragment>
              : null
            }

          </Form.Group>

          <center>
            <Button
              variant="secondary"
              onClick={() => (handleClose(false))}
              style={{marginTop:"10px"}}
            >
              Cancel
            </Button>
            &nbsp;
            { budgetItem.docType !== "RELEASE"
              ? <Fragment>
                  { hasInventory
                    ? <Fragment>
                        <Button
                          variant="warning"
                          onClick={() => navigate(`/projectinventorydetails/${fundCode}/${groupName}/${budgetId}`)}
                          style={{marginTop:"10px"}}
                        >
                          Go to Inventory Item
                        </Button>
                      </Fragment>
                    : <Fragment>
                        <Button
                          variant="warning"
                          onClick={addInventoryItem}
                          style={{marginTop:"10px"}}
                        >
                          Create Inventory Item
                        </Button>
                      </Fragment>
                  }
                </Fragment>
              : null
            }
          </center>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DetailsBudgetItemsListInventoryModal

