import { useState } from "react";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../navigation/MainHeader";
import CustomLoader from '../ui-common/CustomLoader';
import classes from "./InputSearchPage.module.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import InsertDataButtonModal from "./InsertDataButtonModal";
import DetailsDataListModal from "./DetailsDataListModal";
import FullTextSearch from "../../utilities/FullTextSearch";
import { listDocumentsWithPagination, listDocumentsWithPaginationNext, listDocumentsWithPaginationPrev } from "../database/get";
import { Fragment } from "react";
import CheckIsAdmin from "../../utilities/CheckIsAdmin";


const InputSearchPage = (props) => {

  // Dynamic Parameters
  const dbName = props.dbName;
  const dbResultLimit = props.dbResultLimit;
  const label = props.label;
  const elementObjectKey = props.elementObjectKey;
  const elementObjectDescKey = props.elementObjectDescKey;
  const displayName = props.displayName;
  const roles = props.roles;
  const isAdmin = CheckIsAdmin(props.roles);

  // Items List
  const [origItems, setOrigItems] = useState([]);
  const [items, setItems] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);
  // Search Value
  const [searchInput, setSearchInput] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  function searchInputListener(event) {
    setSearchInput(event.target.value);
    setItems(FullTextSearch(origItems, event.target.value, elementObjectKey, true));
  }

  async function generateList() {
    setLoaderState(true);
    let itemResult;
    try {
      itemResult = await listDocumentsWithPagination(dbName, dbResultLimit, elementObjectKey, "asc");
      if (!itemResult) {
        setOrigItems([]);
        setItems([]);
        setLoaderState(false);
        return;
      }
    } catch(error) {
      console.log(`Error in DB query: ${error}`);
      setOrigItems([]);
      setItems([]);
      setLoaderState(false);
      return;
    }
    setOrigItems(itemResult.docs);
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext() {
    setLoaderState(true);
    const itemResult = await listDocumentsWithPaginationNext(dbName, lastVisible, dbResultLimit, elementObjectKey, "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev() {
    setLoaderState(true);
    const itemResult = await listDocumentsWithPaginationPrev(dbName, firstVisible, dbResultLimit, elementObjectKey, "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  async function onSuccessConfirmation(status) {
    if (status) {
      await generateList();
    }
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center><h3 style={{marginTop:15}}>{label}</h3></center>
      <div className={classes.mainInputContainer}>
      <center>
      <Form>
      <Form.Group className="mb-3" controlId="inputItem">
      <InputGroup className="mb-1" size="sm" style={{display:"inline-block", marginTop:5}}>
        <Button
          variant="warning"
          onClick={() => generateList()}
        >
          Generate List
        </Button>
        &nbsp;
        { isAdmin
          ? <Fragment>
              OR
              &nbsp;
              <InsertDataButtonModal
                displayName={displayName}
                elementObjectKey={elementObjectKey}
                dbName={dbName}
                label={label}
                onSuccessConfirmation={onSuccessConfirmation}
              />
            </Fragment>
          : null
        }
      </InputGroup>
      <InputGroup className="mb-1" size="sm"style={{marginTop:5}}>
      <InputGroup.Text id={label}>{label}</InputGroup.Text>
      <Form.Control
        type="searchItemId" placeholder="Type Search Text here" value={searchInput}
        onChange={searchInputListener}
      />
      </InputGroup>
      </Form.Group>
      </Form>
        <ListGroup style={{overflow:"scroll", maxHeight:"350px"}}>
        {
          items.map((val, index) => (
            <DetailsDataListModal
              key={val[elementObjectKey]}
              displayName={displayName}
              docId={val.docId}
              label={label}
              dbName={dbName}
              element={val[elementObjectKey]}
              elementDescription={val[elementObjectDescKey]}
              elementObjectKey={elementObjectKey}
              elementObjectDescKey={elementObjectDescKey}
              onSuccessConfirmation={onSuccessConfirmation}
              roles={roles}
            />
          ))
        }
        </ListGroup>
        <br></br>
          { disablePrevButton
            ? null
            : <Button variant="warning" onClick={() => getPrev()}>Previous</Button>
          }
          &nbsp;&nbsp;
          { disableNextButton
            ? null
            : <Button variant="warning" onClick={() => getNext()}>Next</Button>
          }
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default InputSearchPage;
