import * as React from "react";
import MainHeader from "../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../components/ui-common/CustomLoader";
import firebase from 'firebase/compat/app';
import Footer from "../components/navigation/Footer";
import { useAuthContext } from "../components/context/useAuthContext";
import { searchDocuments } from "../components/database/search";
import ListGroup from "react-bootstrap/ListGroup";
import { Fragment } from "react";
import { DateTime } from "luxon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
// import { useAuthContext } from "../components/context/useAuthContext";


const Home = () => {

  const { user } = useAuthContext();
  const [projectsList, setProjectsList] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("Use effect");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchProjects() {
    const dateTodayMinus60 = DateTime.now().minus({ days: 60 }).toFormat('yyyy-MM-dd');
    const searchFilters = [
      {key: "endDate", type: ">", inputValue: dateTodayMinus60}
    ]
    const projectResult = await searchDocuments("projects", searchFilters, 25, "endDate", "desc");
    let filteredResults = [];
    projectResult.docs.forEach(function(record) {
      if (record.unspentAllotment && record.unspentAllotment > 0) {
        filteredResults.push(record);
      }
    });
    setProjectsList(filteredResults);
  }

  const resultsTipHelp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Results can be less than 10 because there may only be less than 10 projects ending in 60 days that still have unused allotments.
    </Tooltip>
  );

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}>
        <MainHeader
          name={displayName}
          roles={roles}
        />
        <center>
        <h4 style={{marginTop:20}}>Top 10 Projects with unused allotments that are ending in 60 days</h4>
        <div className={classes.viewBox}>
        <ListGroup style={{overflowY:"scroll", maxHeight:"300px"}}>
        {
          projectsList.map((project) => (
            <ListGroup.Item
              key={`${project.fundCode}`}
              action
              onClick={() => navigate(`/projectdetails/${project.fundCode}`)}
            >
              <b>Fund Code:</b> {project.fundCode} &nbsp;
              { project.description
                ? <Fragment><b>Description:</b> {project.description} &nbsp;</Fragment>
                : null
              }
              <b>Leader:</b> {project.leader} &nbsp;
              <b>End Date:</b> {project.endDate} &nbsp;
              <b>Unused Allotment:</b> {project.unspentAllotment} &nbsp;
            </ListGroup.Item>
          ))
        }
        </ListGroup>
        { projectsList.length < 10
          ? <OverlayTrigger
              placement="right-start"
              delay={{ show: 250, hide: 400 }}
              overlay={resultsTipHelp}
            >
              <Button variant="link">
                Results less than 10?
              </Button>
            </OverlayTrigger>
          : null
        }
        </div>
        </center>

        <br></br>
        <br></br>
        <Footer />
      </div>
    </CustomLoader>
  );
}

export default Home;
