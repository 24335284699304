import { db } from '../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, startAfter, limit } from 'firebase/firestore/lite';


export const searchNextDocuments = async (collectionName, searchFilters, searchLimit, lastVisible, sortOrderKey, sortOrderDirection) => {
  const collectionRef = collection(db, collectionName);
  let q;
  let orderByFilter;
  let isNextAvailable = false;

  if(!validateInputs(collectionName, searchFilters, searchLimit, lastVisible)) {
    return;
  }
  // Parse Inputs
  const queryFilters = searchFilters.map((searchFilter) => where(searchFilter.key,'==',searchFilter.inputValue));
  if (sortOrderKey) {
    orderByFilter = orderBy(sortOrderKey, "asc");
    if (sortOrderDirection) {
      orderByFilter = orderBy(sortOrderKey, sortOrderDirection);
    }
  }
  // Generate Query
  if (orderByFilter) {
    q = query(collectionRef,
      ...queryFilters,
      orderByFilter,
      startAfter(lastVisible),
      limit(searchLimit));
  } else {
    q = query(collectionRef,
      ...queryFilters,
      startAfter(lastVisible),
      limit(searchLimit));
  }
  // console.log(q);
  // Execute Query
  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push(doc.data());
    }
  });

  const parsedDocs = tempList;
  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];

  // Check if there are next documents for next button enablement
  let nextq;
  if (orderByFilter) {
    nextq = query(collectionRef,
      ...queryFilters,
      orderByFilter,
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  } else {
    nextq = query(collectionRef,
      ...queryFilters,
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  }

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: parsedDocs,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable,
    isPrevAvailable: true
  })
};

function validateInputs(collectionName, searchFilters, searchLimit, lastVisible) {
  if (!collectionName || !searchFilters || !searchLimit || !lastVisible) {
    alert('Incomplete database search parameters.');
    return false;
  }
  return true;
}
