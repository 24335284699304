// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { setPersistence, browserLocalPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import classes from "./SignIn.module.css";
import { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Footer from '../components/navigation/Footer';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from 'react-bootstrap';


const SignIn = () => {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [noAuthError, setNoAuthError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  async function onLogin(event) {
    setLoaderState(true);
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        // set auth state persistence, at session level
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            sessionStorage.setItem("email", email);
          })
          .catch((error) => {
            // Handle Errors here.
        });
        navigate("/home")
        setLoaderState(false);
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setErrorMessage("Login Failed.");
        setLoaderState(false);
        setNoAuthError(false);
        alert("Login Failed.");
    });

  }

  async function forgotPassword() {
    if (!email) {
      setErrorMessage("Reset password email not sent because email is missing.");
      return;
    }
    setLoaderState(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setNoAuthError(true);
      setLoaderState(false);
      alert("Password reset email sent");
    } catch (error) {
      setLoaderState(false);
      setErrorMessage("Reset password email not sent.");
      setNoAuthError(false);
      alert(`Reset password error: ${error}`);
    }
  }

  if (!isSignedIn) {
    return (
      <Fragment>
      <br></br>
      <img
        src={process.env.PUBLIC_URL + 'images/cafs_logo.png'}
        alt="CAFS Logo"
        style={{width: "200px", height: "150px"}}
      />
      <h1>
        College of Agriculture and Food Science
      </h1>
      <h2>Budget and Inventory Management System</h2>
      <br></br>
      <div className={classes.mainInputContainer}>
        <form>
        <center>
          <div>
            <label htmlFor="email-address">
              Email address:
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              required
              placeholder="Email address"
              onChange={(e)=>setEmail(e.target.value)}
            />
            <br></br>
            <label htmlFor="password">
              Password: &nbsp;
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              placeholder="Password"
              onChange={(e)=>setPassword(e.target.value)}
            />
            <Button
              onClick={forgotPassword}
              variant='link'
              style={{margin:"-10px"}}
            >
              Forgot Password?
            </Button>
          </div>
          { noAuthError
            ? null
            : <Alert
                key="danger"
                variant="danger"
                style={{textAlign:"left"}}
              >
                {errorMessage}
              </Alert>
          }
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            : <Button
                onClick={onLogin}
                variant="warning"
              >
                Login
              </Button>
          }
        </center>
        </form>
      </div>
      <Footer />
      </Fragment>
    );
  }
  return (
    <div>
      { navigate('/home') }
    </div>
  );
}

export default SignIn;
