import { useState, useEffect, Fragment } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./AllocGroupEdit.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { updateDocument } from "../../components/database/update";
import { searchDocuments } from "../../components/database/search";
import InsertBudgetItemButtonModal from "./InsertBudgetItemButtonModal";
import DetailsBudgetItemsListModal from "./DetailsBudgetItemsListModal";
import DeleteConfirmationModal from "../../components/ui-common/DeleteConfirmationModal";
import { deleteAllocationGroup } from "../../components/database/delete";
import CalculateAllotment from "../../utilities/CalculateAllotment";
import { BudgetItemTypeEnum } from "../../models/budget-item-type";
import CalculateProjectTotals from "../../utilities/CalculateProjectTotals";
import { getDocument } from "../../components/database/get";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import RolesChecker from "../../utilities/RolesChecker";
import CheckIsAdmin from "../../utilities/CheckIsAdmin";
import { evaluate, round } from 'mathjs';
import { ProjectTypeEnum } from "../../models/project-type";
import InsertDataDropdownModal from "../../components/ui-common/InsertDataDropdownModal";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";


const AllocGroupEdit = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formHasNoChanges, setformHasNoChanges] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Form inputs
  const fundCode = params.fundCode;
  const groupName = params.groupName;
  const [projectType, setProjectType] = useState("");
  const [controller, setController] = useState("");
  const [allotmentPercentage, setAllotmentPercentage] = useState("");
  const [allocationExpenseType, setAllocationExpenseType] = useState(params.allocationExpenseType ?? "");
  const [hasAllotmentPercentageChanged, setHasAllotmentPercentageChanged] = useState(false);

  const [totalAllotment, setTotalAllotment] = useState("");
  const [totalObligations, setTotalObligations] = useState("");
  const [totalDisbursements, setTotalDisbursements] = useState("");
  const [runningBalance, setRunningBalance] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [budgetItemList, setBudgetItemList] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [createDate, setCreateDate] = useState("");

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
          setIsAdmin(CheckIsAdmin(parsedUserRoles));
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(parsedUserRoles);
              setIsAdmin(CheckIsAdmin(parsedUserRoles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchAllocationGroup();
    searchBudgetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchAllocationGroup() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
    searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
    if (params.allocationExpenseType) {
      searchFilters.push({key: "allocationExpenseType", type: "==", inputValue: params.allocationExpenseType});
    }
    const groupResult = await searchDocuments("allocation_groups", searchFilters, 1, "fundCode", "asc");
    populateFields(groupResult.docs[0]);
    setDocumentId(groupResult.docs[0].docId);
  }

  async function searchBudgetItems() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
    searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
    const budgetListResult = await searchDocuments("budget_items", searchFilters, 1000, "date", "asc");
    // populateFields(budgetListResult.docs[0]);
    if (budgetListResult?.docs) {
    //   console.log(budgetListResult.docs);
      setBudgetItemList(budgetListResult.docs);
      return budgetListResult.docs;
    } else {
      setBudgetItemList([]);
      return [];
    }
  }

  function populateFields(allocGroupDocument) {
    setProjectType(allocGroupDocument.projectType ?? ProjectTypeEnum.ONE_TIME);
    setAllocationExpenseType(allocGroupDocument.allocationExpenseType);
    setController(allocGroupDocument.controller);
    setAllotmentPercentage(allocGroupDocument.allotmentPercentage);
    setTotalAllotment(allocGroupDocument.totalAllotment);
    setTotalObligations(allocGroupDocument.totalObligations);
    setTotalDisbursements(allocGroupDocument.totalDisbursements);
    setRunningBalance(allocGroupDocument.runningBalance);
    setCreatedBy(allocGroupDocument.createdBy);
    setCreateDate(allocGroupDocument.createDate.toDate().toLocaleString());
  }

  function allocationExpenseTypeListener(value) {
    setAllocationExpenseType(value);
    setformHasNoChanges(false);
  }

  function controllerListener(event) {
    setController(event.target.value);
    setformHasNoChanges(false);
  }

  function allotmentPercentageListener(event) {
    setAllotmentPercentage(event.target.value);
    setHasAllotmentPercentageChanged(true);
    setformHasNoChanges(false);
  }

  async function editGroupAllocation(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }

    if (!CalculateAllotment(allotmentPercentage, 1)) {
      alert("Incorrect allotment percentage formula.");
      setValidated(false);
      return;
    }
    // Check for changes to Allotment Percentage
    // If yes, Recalculate all Budget items with docType = RELEASE
    if (hasAllotmentPercentageChanged) {
      for (const item  of budgetItemList) {
        if (item.docType === BudgetItemTypeEnum.RELEASE) {
          const allotment = CalculateAllotment(allotmentPercentage, item.amount);
          await updateDocument("budget_items", item.docId, {allotment: allotment}, displayName);
        }
      }
      const budgetItemsList = await searchBudgetItems();
      await calculateTotals(budgetItemsList);
    }

    const groupAllocationData = {
      groupName: groupName,
      projectType: projectType,
      controller: controller ?? " ",
      allotmentPercentage: allotmentPercentage,
    };
    if (allocationExpenseType){
      groupAllocationData.allocationExpenseType = allocationExpenseType;
    }
    // console.log(documentId);

    setLoaderState(true);
    try {
      await updateDocument("allocation_groups", documentId, groupAllocationData, displayName);
      alert ("Successfully edited allocation_group.");
    } catch (error) {
      console.log(error);
      alert ("Error editing allocation_group.");
    }
    setLoaderState(false);
    setValidated(false);
    setformHasNoChanges(true);
    setHasAllotmentPercentageChanged(false);
  }

  async function deleteAllocGroup() {
    try {
      setLoaderState(true);
      const result = await deleteAllocationGroup(fundCode, groupName);
      console.log(result);
      setLoaderState(false);
      alert ("Deleted successfully.");
      navigate(`/projectdetails/${fundCode}`);
    } catch (error) {
      console.log(error);
      alert ("Error during deletion.");
      setLoaderState(false);
    };
  }

  async function getInsertConfirmation(isSuccess) {
    if (isSuccess){
      const budgetItemsList = await searchBudgetItems();
      await calculateTotals(budgetItemsList);
    }
  }
  async function getEditDeleteConfirmation(isSuccess) {
    if (isSuccess){
        const budgetItemsList = await searchBudgetItems();
        await calculateTotals(budgetItemsList);
    }
  }
  async function calculateTotals(budgetItems) {
    let localTotalAllotment = 0;
    let localTotalObligations = 0;
    let localTotalDisbursements = 0;
    for (const item of budgetItems) {
      if (item.allotment) {
        localTotalAllotment += parseFloat(item.allotment);
      }
      if (item.obligations) {
        localTotalObligations += parseFloat(item.obligations);
      }
      if (item.disbursements) {
        localTotalDisbursements += parseFloat(item.disbursements);
      }
    }
    // console.log(localTotalAllotment);
    // console.log(localTotalObligations);
    // console.log(localTotalDisbursements);
    localTotalAllotment = round(localTotalAllotment);
    localTotalObligations = round(localTotalObligations);
    localTotalDisbursements = round(localTotalDisbursements);
    const localRunningBalExpr = `${localTotalAllotment} - (${localTotalObligations} + ${localTotalDisbursements})`;
    const localRunningBalance = round(evaluate(localRunningBalExpr), 2);
    setTotalAllotment(localTotalAllotment);
    setTotalObligations(localTotalObligations);
    setTotalDisbursements(localTotalDisbursements);
    setRunningBalance(localRunningBalance);
    const totals = {
      totalAllotment: localTotalAllotment,
      totalObligations: localTotalObligations,
      totalDisbursements: localTotalDisbursements,
      runningBalance: localRunningBalance
    };
    await updateDocument("allocation_groups", documentId, totals, displayName);
    const projectResult = await getDocument("projects", "fundCode", fundCode);
    const allocGroupResult = await getDocument("allocation_groups", "fundCode", fundCode);
    await CalculateProjectTotals(allocGroupResult, projectResult[0]);
  }

  const allotmentPercentageTipHelp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Allotment percentage is a formula used to calculate the allotment/share of the allocation group based on the budget releases.
      You use the word "base" as the Base amount to be calculated by your formula.
    </Tooltip>
  );

  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Allocation Group Details
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={editGroupAllocation}>
        <Form.Group className="mb-3" controlId="editGroupAllocation">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="groupName">
            Group Name:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={groupName}
          />
          &nbsp;
          <InputGroup.Text id="projectType">
            Project Type:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={projectType}
          />
          </InputGroup>

          { projectType === ProjectTypeEnum.REVOLVING
            ? <Fragment>
                { isAdmin
                  ? <Fragment>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id="allocationExpenseType">
                        Allocation Expense Type:
                      </InputGroup.Text>
                        <Form.Control
                          disabled
                          type="text"
                          value={allocationExpenseType}
                        />
                      <InsertDataDropdownModal
                        validated={validated}
                        selectedElement={allocationExpenseType}
                        onSelectedElement={allocationExpenseTypeListener}
                        displayName={displayName}
                        elementObjectKey="allocationExpenseType"
                        dbName="allocation_expense_type"
                        label="Allocation Expense Type"
                        required={true}
                      />
                      </InputGroup>
                      <CustomFeedbackValidation
                        validated={validated}
                        evaluatedValue={allocationExpenseType}
                        label="Allocation Expense Type"
                      />
                    </Fragment>
                  : <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="allocationExpenseType">
                      Allocation Expense Type:
                    </InputGroup.Text>
                      <Form.Control
                        disabled
                        type="text"
                        value={allocationExpenseType}
                      />
                    </InputGroup>
                }
              </Fragment>
            : null
          }

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="controller">
            Controller:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="text"
            placeholder="Controller Name"
            value={controller}
            onChange={controllerListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="createdBy">
            Created By:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createdBy}
          />
          <InputGroup.Text id="createDate">
            Create Timestamp:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createDate}
          />
          </InputGroup>

          <Form.Label column="lg">Summary and Calculations</Form.Label>

          { allotmentPercentage
            ? <Fragment>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="allotmentPercentage">
                  Allotment Percentage:
                </InputGroup.Text>
                <Form.Control
                  disabled={!isAdmin}
                  type="text"
                  placeholder="Share Percentage of the Allocation Group."
                  value={allotmentPercentage}
                  onChange={allotmentPercentageListener}
                />
                <Form.Control.Feedback type="invalid">
                  Allocation percentage is required.
                </Form.Control.Feedback>
                </InputGroup>
                <OverlayTrigger
                  placement="left-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={allotmentPercentageTipHelp}
                >
                  <Button variant="link" size="sm">
                    Allotment Percentage Help?
                  </Button>
                </OverlayTrigger>
              </Fragment>
            : null
          }


          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="totalAllotment">
            Total Allotment:
          </InputGroup.Text>
          <Form.Control
            type="text"
            disabled
            value={totalAllotment}
          />
          <InputGroup.Text id="totalObligations">
            Total Obligations:
          </InputGroup.Text>
          <Form.Control
            type="text"
            disabled
            value={totalObligations}
          />
          <InputGroup.Text id="totalDisbursements">
            Total Disbursements:
          </InputGroup.Text>
          <Form.Control
            type="text"
            disabled
            value={totalDisbursements}
          />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="runningBalance">
            Running Balance:
          </InputGroup.Text>
          <Form.Control
            type="text"
            disabled
            value={runningBalance}
          />
          </InputGroup>

          <br></br>
          <center>
            <Button
              variant="secondary"
              onClick={() => navigate(`/projectdetails/${fundCode}`)}
            >
              Back to Project
            </Button>
            { isAdmin
              ? <Fragment>
                  &nbsp;
                  <Button
                    disabled={formHasNoChanges}
                    variant="warning"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                  &nbsp;
                  <DeleteConfirmationModal
                    label='Allocation Group'
                    message1="All budget items and inventory attached to this group will also be deleted."
                    message2="Are you sure you want to delete this allocation Group?"
                    onDeleteConfirmation={deleteAllocGroup}
                  />
                  </Fragment>
              : null
            }
          </center>

          <Form.Label column="lg">Budget Items</Form.Label>

        </Form.Group>
        </Form>

        <ListGroup>
        { budgetItemList.length > 0
          ? budgetItemList.map((row) => (
              <DetailsBudgetItemsListModal
                key={row.budgetId}
                budgetItem={row}
                displayName={displayName}
                onEditDeleteConfirmation={getEditDeleteConfirmation}
                allotmentPercentage={allotmentPercentage}
                isAdmin={isAdmin}
              />
            ))
          : null
        }
        </ListGroup>
        <br></br>
        { isAdmin
          ? <InsertBudgetItemButtonModal
              fundCode={fundCode}
              displayName={displayName}
              groupName={groupName}
              allotmentPercentage={allotmentPercentage}
              projectType={projectType}
              onInsertConfirmation={getInsertConfirmation}
            />
          : null
        }

      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default AllocGroupEdit;
