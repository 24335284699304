import { evaluate, round } from 'mathjs';

function CalculateAllotment(allotmentPercentage, amount) {

  const equation = allotmentPercentage.replace("base", amount);
  try {
    const result = round(evaluate(equation), 2);
    return result;
  } catch (error) {
    console.error('Invalid equation:', error);
    return false;
  }
}


export default CalculateAllotment

