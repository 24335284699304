import * as React from "react";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebaseConfig';
import { useState, useEffect, Fragment } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./InventoryEdit.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Footer from "../../components/navigation/Footer";
import { updateDocument } from "../../components/database/update";
import DeleteConfirmationModal from "../../components/ui-common/DeleteConfirmationModal";
import { searchDocuments } from "../../components/database/search";
import { deleteDocument } from "../../components/database/delete";
import { InventoryStatusList } from "../../models/inventory-status";
import ImagePopUpModal from "../../components/ui-common/ImagePopUpModal";
import InsertDataDropdownModal from "../../components/ui-common/InsertDataDropdownModal";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import RolesChecker from "../../utilities/RolesChecker";
import CheckIsAdmin from "../../utilities/CheckIsAdmin";


const InventoryEdit = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formHasNoChanges, setformHasNoChanges] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Form inputs
  const fundCode = params.fundCode;
  const groupName = params.groupName;
  const budgetId = params.budgetId;
  const [documentId, setDocumentId] = useState("");
  const [particulars, setParticulars] = useState("");
  const [owner, setOwner] = useState("");
  const [imageReferenceUrl, setImageReferenceUrl] = useState("");
  const [imagePresignedUrl, setImagePresignedUrl] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [dateReceived, setDateReceived] = useState("");
  const [comments, setComments] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [inventoryStatus, setInventoryStatus] = useState("");

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setIsAdmin(CheckIsAdmin(parsedUserRoles));
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(parsedUserRoles);
              setIsAdmin(CheckIsAdmin(parsedUserRoles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchInventory() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
    searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
    searchFilters.push({key: "budgetId", type: "==", inputValue: budgetId});
    const inventoryResult = await searchDocuments("inventory", searchFilters, 1000, "dateReceived", "asc");
    // console.log(inventoryResult.docs[0]);
    populateFields(inventoryResult.docs[0]);
  }

  function populateFields(inventoryDocument) {
    setDocumentId(inventoryDocument.docId);
    setParticulars(inventoryDocument.particulars);
    setOwner(inventoryDocument.owner);
    setDateReceived(inventoryDocument.dateReceived);
    setComments(inventoryDocument.comments);
    setInventoryStatus(inventoryDocument.inventoryStatus);
    setCreatedBy(inventoryDocument.createdBy);
    setImagePresignedUrl(inventoryDocument.imageReferenceUrl);
    setCreateDate(inventoryDocument.createDate.toDate().toLocaleString());
  }

  function ownerListener(person) {
    setOwner(person);
    setformHasNoChanges(false);
  }
  function dateReceivedListener(event) {
    setDateReceived(event.target.value);
    setformHasNoChanges(false);
  }
  function commentsListener(event) {
    setComments(event.target.value);
    setformHasNoChanges(false);
  }
  function inventoryStatusListener(value) {
    setInventoryStatus(value);
    setformHasNoChanges(false);
  }
  function imagerReferenceListener(event) {
    setImageFileName(event.target.value);
    setImageReferenceUrl(event.target.files[0]);
    setformHasNoChanges(false);
  }

  async function editInventory(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const inventoryData = {
      owner: owner,
      dateReceived: dateReceived,
      comments: comments ?? " ",
      inventoryStatus: inventoryStatus ?? " ",
    };
    setLoaderState(true);
    try {
      if (imageReferenceUrl) {
        const imgRef = await uploadToFirebaseStorage(imageReferenceUrl);
        const imgHttpUrl = await getDownloadURL(imgRef);
        setImagePresignedUrl(imgHttpUrl);
        inventoryData.imageReferenceUrl = imgHttpUrl;
        inventoryData.imageFileName = imageFileName;
      }
      await updateDocument("inventory", documentId, inventoryData, displayName);
      alert ("Successfully edited inventory.");
    } catch (error) {
      console.log(error);
      alert ("Error editing inventory.");
    }
    setLoaderState(false);
    setValidated(false);
    setformHasNoChanges(true);
  }

  async function deleteInventory(status) {
    if (status) {
      try {
        setLoaderState(true);
        const searchFilters = [];
        searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
        searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
        searchFilters.push({key: "budgetId", type: "==", inputValue: budgetId});
        const budgetItemResults = await searchDocuments("budget_items", searchFilters, 1000, "createDate", "asc");
        await updateDocument("budget_items", budgetItemResults.docs[0].docId, {hasInventory: false}, displayName);
        await deleteDocument("inventory", documentId);
        await deleteFirebaseStorageFile();
        setLoaderState(false);
        alert ("Deleted successfully.");
        navigate(`/`);
      } catch (error) {
        console.log(error);
        alert ("Error during deletion.");
        setLoaderState(false);
      };
    }
  }

  async function uploadToFirebaseStorage(file) {
    const storageRef = ref(storage, `/inventory/${fundCode}/${groupName}/${budgetId}/${file.name}`);
    await uploadBytes(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  async function deleteFirebaseStorageFile() {
    const storageRef = ref(storage, `/inventory/${fundCode}/${groupName}/${budgetId}`);
    const objectsList = await listAll(storageRef);
    for (const object of objectsList.items) {
      console.log(object.fullPath);
      await deleteObject(ref(storage, object.fullPath)).then(async (snapshot) => {});
    }
    return storageRef;
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Inventory Details
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={editInventory}>
        <Form.Group className="mb-3" controlId="editInventory">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          <InputGroup.Text id="groupName">
            Group Name:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={groupName}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="particulars">
            Particulars:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={particulars}
          />
          </InputGroup>

          { isAdmin
            ? <Fragment>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="owner">
                  Owner:
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  value={owner}
                />
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={owner}
                  onSelectedElement={ownerListener}
                  displayName={displayName}
                  elementObjectKey="person"
                  dbName="persons"
                  label="Owner"
                  required={true}
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={owner}
                  label="Owner"
                />
              </Fragment>
            : <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="owner">
                Owner:
              </InputGroup.Text>
              <Form.Control
                disabled
                type="text"
                value={owner}
              />
              </InputGroup>
          }

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="inventoryStatus">
            Status:
          </InputGroup.Text>
          { isAdmin
            ? <Fragment>
                <Dropdown
                  id="dropdown-button"
                  as={Form.Control}
                  value={inventoryStatus}
                >
                <Dropdown.Toggle variant="warning">
                  {inventoryStatus}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    InventoryStatusList.map((val) => (
                      <Dropdown.Item
                        key={val}
                        onClick={() => {inventoryStatusListener(val)}}
                      >
                        {val}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            : <Form.Control
                disabled={!isAdmin}
                type="text"
                value={inventoryStatus}
              />
          }

          <InputGroup.Text id="dateReceived">
            Date Received:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="date"
            value={dateReceived}
            inputMode='none'
            onChange={dateReceivedListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="imageReference">Image Reference</InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="file"
            value={imageFileName}
            onChange={imagerReferenceListener}
          />
          </InputGroup>
          { imagePresignedUrl
            ? <ImagePopUpModal
              key={imagePresignedUrl}
              imgUrlIcon={imagePresignedUrl}
              imgUrl={imagePresignedUrl}
              />
            : null
          }

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="comments">
            Comments:
          </InputGroup.Text>
          <Form.Control
            disabled={!isAdmin}
            type="text"
            placeholder="Comments the inventory. (optional)"
            value={comments}
            onChange={commentsListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="createdBy">
            Created By:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createdBy}
          />
          <InputGroup.Text id="createDate">
            Create Timestamp:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createDate}
          />
          </InputGroup>

          <br></br>
          <center>
            <Button
              variant="secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            { isAdmin
              ? <Fragment>
                  &nbsp;
                  <Button
                    type="submit"
                    variant="warning"
                    disabled={formHasNoChanges}
                  >
                    Save Changes
                  </Button>
                  &nbsp;
                  <DeleteConfirmationModal
                    label="Inventory"
                    message1="Inventory will be deleted."
                    message2="Are you sure you want to delete this inventory?"
                    onDeleteConfirmation={deleteInventory}
                  />
                </Fragment>
              : null
            }
          </center>

        </Form.Group>
        </Form>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default InventoryEdit;
