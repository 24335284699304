import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../../components/database/insert";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CalculateAllotment from "../../utilities/CalculateAllotment";
import ValidateKeyInputs from "../../utilities/ValidateKeyInputs";
import InsertDataDropdownModal from "../../components/ui-common/InsertDataDropdownModal";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import ValidateDropDowns from "../../utilities/ValidateDropDowns";
import { ProjectTypeEnum } from "../../models/project-type";
import { Fragment } from "react";


const InsertAllocGroupButtonModal = (props) => {

  const fundCode = props.fundCode;
  const displayName = props.displayName;
  const projectType = props.projectType;
  const [groupName, setGroupName] = useState("");
  const [controller, setController] = useState("");
  const [allocationExpenseType, setAllocationExpenseType] = useState("");
  const [allotmentPercentage, setAllotmentPercentage] = useState("");

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  function handleClose(status) {
    clearInputs(status);
    setShow(false);
  };
  function handleShow() {
    setShow(true);
  };

  function groupNameListener(group) {
    setGroupName(group);
  }
  function controllerListener(event) {
    setController(event.target.value);
  }
  function allocationExpenseTypeListener(value) {
    setAllocationExpenseType(value);
  }
  function allotmentPercentageListener(event) {
    setAllotmentPercentage(event.target.value);
  }

  async function createNewGroup(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (!CalculateAllotment(allotmentPercentage, 1)) {
      alert("Incorrect allotment percentage formula.");
      setValidated(false);
      return;
    }
    const groupData = {
      fundCode: fundCode,
      groupName: groupName,
      projectType: projectType,
      allotmentPercentage: allotmentPercentage,
      controller: controller ?? " "
    };
    if (projectType === ProjectTypeEnum.REVOLVING) {
      groupData.allocationExpenseType = allocationExpenseType;
    }
    // console.log(groupData);
    if (!ValidateDropDowns(groupData, ["groupName"])) {
      return;
    }
    if (!ValidateKeyInputs(groupData, "groupName")) {
      setValidated(false);
      alert ("Invalid character detected. / is not allowed.");
      return;
    }
    try {
      setLoaderState(true);
      const uniqueKeyFilter = [
        { key: "fundCode", inputValue: fundCode},
        { key: "groupName", inputValue: groupName}
      ];
      if (allocationExpenseType) {
        uniqueKeyFilter.push({ key: "allocationExpenseType", inputValue: allocationExpenseType});
      }
      const insertResult = await insertDocument(
        "allocation_groups",
        groupData,
        "",
        false,
        uniqueKeyFilter,
        true,
        displayName
      );
      setLoaderState(false);
      if (!insertResult) {
        setValidated(false);
        return;
      }
      alert ("Allocation group created successfully.");
      handleClose(true);
    } catch (error) {
      console.log(error);
      alert ("Error during group creation.");
      setLoaderState(false);
    };
  }

  function clearInputs() {
    setController("");
    setGroupName("");
    setAllotmentPercentage("");
    setAllocationExpenseType("");
    setValidated(false);
    props.onInsertConfirmation(true);
  }

  const allotmentPercentageTipHelp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Allotment percentage is a formula used to calculate the allotment/share of the allocation group based on the budget releases.
      You use the word "base" as the Base amount to be calculated by your formula.
    </Tooltip>
  );


  return (
    <React.Fragment>
      <Button
        variant="warning"
        onClick={handleShow}
      >
          Create Allocation Group
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={() => (handleClose(false))}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#064422",
            color:"#fff"
          }}
        >
          <Modal.Title>Create New Allocation Group</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
          <Form noValidate validated={validated} onSubmit={createNewGroup}>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="groupName">
              Group Name:
            </InputGroup.Text>
            <InsertDataDropdownModal
              validated={validated}
              selectedElement={groupName}
              onSelectedElement={groupNameListener}
              displayName={displayName}
              elementObjectKey="groupName"
              dbName="groups"
              label="Group"
              required={true}
            />
            </InputGroup>
            <CustomFeedbackValidation
              validated={validated}
              evaluatedValue={groupName}
              label="Group"
            />

            { projectType === ProjectTypeEnum.REVOLVING
              ? <Fragment>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="allocationExpenseType">
                    Allocation Expense Type:
                  </InputGroup.Text>
                  <InsertDataDropdownModal
                    validated={validated}
                    selectedElement={allocationExpenseType}
                    onSelectedElement={allocationExpenseTypeListener}
                    displayName={displayName}
                    elementObjectKey="allocationExpenseType"
                    dbName="allocation_expense_type"
                    label="Allocation Expense Type"
                    required={true}
                  />
                  </InputGroup>
                  <CustomFeedbackValidation
                    validated={validated}
                    evaluatedValue={allocationExpenseType}
                    label="Allocation Expense Type"
                  />
                </Fragment>
              : null
            }

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="allotmentPercentage">
              Allotment Percentage:
            </InputGroup.Text>
            <Form.Control
              required
              type="text"
              placeholder="Example of 60% share. base*.60"
              value={allotmentPercentage}
              onChange={allotmentPercentageListener}
            />
            <Form.Control.Feedback type="invalid">
              Allotment percentage is required.
            </Form.Control.Feedback>
            </InputGroup>
            <OverlayTrigger
              placement="left-start"
              delay={{ show: 250, hide: 400 }}
              overlay={allotmentPercentageTipHelp}
            >
              <Button variant="link" size="sm">
                Allotment Percentage Help?
              </Button>
            </OverlayTrigger>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="controller">
              Controller:
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Name of the controller. (optional)"
              value={controller}
              onChange={controllerListener}
            />
            </InputGroup>

            { loaderState
              ? <center>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </center>
              : <center>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    type="submit"
                    variant="warning"
                  >
                    Create
                  </Button>
                </center>
            }

          </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#c5f4e6"
          }}
        >
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertAllocGroupButtonModal

