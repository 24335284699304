import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./AllocGroupEditInventory.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { searchDocuments } from "../../components/database/search";
import DetailsBudgetItemsListInventoryModal from "./DetailsBudgetItemsListInventoryModal";
import RolesChecker from "../../utilities/RolesChecker";


const AllocGroupEditInventory = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);

  // Form inputs
  const fundCode = params.fundCode;
  const groupName = params.groupName;
  const [controller, setController] = useState("");
  const [budgetItemList, setBudgetItemList] = useState([]);

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(parsedUserRoles);
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchAllocationGroup();
    searchBudgetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchAllocationGroup() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
    searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
    const groupResult = await searchDocuments("allocation_groups", searchFilters, 1, "fundCode", "asc");
    populateFields(groupResult.docs[0]);
  }

  async function searchBudgetItems() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", type: "==", inputValue: fundCode});
    searchFilters.push({key: "groupName", type: "==", inputValue: groupName});
    const budgetListResult = await searchDocuments("budget_items", searchFilters, 1000, "date", "asc");
    // populateFields(budgetListResult.docs[0]);
    if (budgetListResult?.docs) {
    //   console.log(budgetListResult.docs);
      // Remove budgetItems where type = RELEASE
      const filteredArray = budgetListResult.docs.filter((budgetItem) => budgetItem.docType !== "RELEASE");
      setBudgetItemList(filteredArray);
      return filteredArray;
    } else {
      setBudgetItemList([]);
      return [];
    }
  }

  function populateFields(allocGroupDocument) {
    setController(allocGroupDocument.controller);
  }


  return (
    <CustomLoader
      isActive={false}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Allocation Group Details for Inventory Creation
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form>
        <Form.Group className="mb-3" controlId="editGroupAllocation">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          <InputGroup.Text id="groupName">
            Group:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={groupName}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="controller">
            Controller:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={controller}
          />
          </InputGroup>

          <Form.Label column="lg">Budget Items</Form.Label>

        </Form.Group>
        </Form>

        <ListGroup>
        { budgetItemList.length > 0
          ? budgetItemList.map((row) => (
              <DetailsBudgetItemsListInventoryModal
                key={row.budgetId}
                budgetItem={row}
                displayName={displayName}
              />
            ))
          : null
        }
        </ListGroup>

      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default AllocGroupEditInventory;
