export const BudgetItemTypeEnum = {
  DV: "DV",
  PR: "PR",
  PO: "PO",
  RELEASE: "RELEASE"
}

export const BudgetItemTypeList = [
  "DV",
  "PR",
  "PO",
  "RELEASE"
]
