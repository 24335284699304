import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectEditInventory.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { getDocument } from "../../components/database/get";
import RolesChecker from "../../utilities/RolesChecker";


const ProjectEditInventory = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);

  // Form inputs
  const fundCode = params.fundCode;
  const [description, setDescription] = useState("");
  const [leader, setLeader] = useState("");
  const [allocGroupList, setAllocGroupList] = useState([]);


  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              const parsedUserRoles = JSON.parse(data.claims?.roles);
              // console.log(parsedUserRoles);
              // console.log(allowedRolesList);
              if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
                alert('Unauthorized access.');
                navigate('/');
              }
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchProject();
    searchAllocationGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchProject() {
    const projectResult = await getDocument("projects", "fundCode", fundCode);
    populateFields(projectResult[0]);
  }

  async function searchAllocationGroups() {
    const groupResult = await getDocument("allocation_groups", "fundCode", fundCode);
    setAllocGroupList(groupResult);
  }

  function populateFields(projectDocument) {
    setDescription(projectDocument.description);
    setLeader(projectDocument.leader);
  }

  return (
    <CustomLoader
      isActive={false}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Project Details for Inventory Creation
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form>
        <Form.Group className="mb-3" controlId="viewProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="description">
            Desciption:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={description}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="project-leader">
            Project Leader:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={leader}
          />
          </InputGroup>

          <Form.Label column="lg">Allocation Groups</Form.Label>
        </Form.Group>
        </Form>

        <ListGroup>
        { allocGroupList.length > 0
          ? allocGroupList.map((row) => (
              <ListGroup.Item
                key={`${row.fundCode}_${row.groupName}`}
                action
                onClick={() => navigate(`/allocgroupdetailsinventory/${fundCode}/${row.groupName}`)}
              >
                <div className="ms-2 me-auto">
                  <b>Name:</b> {row.groupName} &nbsp;
                </div>
              </ListGroup.Item>
            ))
          : null
        }
        </ListGroup>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectEditInventory;
